import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api'; // Import the Axios instance

const initialState = {
  isAuthenticated: false,
  user: null,
  status: 'idle',
  error: null,
};

// Async thunk for login

export const fetchAllUsers = createAsyncThunk('/bots/fetchAllUsers', async (configBot) => {
  const response = await api.post('/getAllUsers', configBot); // Adjust endpoint as needed
  return response.data
});
export const deleteUserSlice = createAsyncThunk('/bots/deleteUserSlice', async (configBot) => {
  const response = await api.post('/deleteUser', configBot); // Adjust endpoint as needed
  return response.data
});
export const addSubscriptionSlice = createAsyncThunk('/bots/addSubscriptionSlice', async (configBot) => {
  const response = await api.post('/addSubscription', configBot); // Adjust endpoint as needed
  return response.data
});
export const checkBalancesSlice = createAsyncThunk('/bots/checkBalancesSlice', async (configBot) => {
  const response = await api.post('/checkAllBalancesOfUser', configBot); // Adjust endpoint as needed
  return response.data
});

const authSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteUserSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUserSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(deleteUserSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addSubscriptionSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addSubscriptionSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(addSubscriptionSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(checkBalancesSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkBalancesSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(checkBalancesSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default authSlice.reducer;
