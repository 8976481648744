import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  buyBnbSlice, fetchBots, createBot, deleteRobot, sellAll, removeAll,
  startRobot, getAllBuys, saveTelegramGroup, getAllSells, editBot, changeStopBuy,
  getHistoryBuys, setupStrategy, addConfigBinanceSlice
} from '../redux/slices/earningsSlice';
import { Container, Card, Button, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';
import { logout, saveChangePassword } from '../redux/slices/authSlice';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import ReactPullToRefresh from 'react-simple-pull-to-refresh'
import "../css/Dashboard.css";
import "../css/pricingCard.css";
import DetailedTrans from './DetailedTrans';
import History from './History';
import animationData from './public/images/working.json';
import animationData2 from './public/images/notWorking.json';
import animationData3 from './public/images/buy.json';
import animationData4 from './public/images/notBuy.json';
import StrategyCard from './StrategyCard';
import DialogError from './DialogError';
const helper = require('./../utils/helper')
const strategies = [
  {
    title: 'Low-Risk',
    description:
      'Focuses on low volatility and reliable assets like Bitcoin and Ethereum, providing stability and steady growth. Normal returns',
    assets: ['BTC', 'ETH'],
    returns: '2-4',
    returnsYear: '15$',
    risk: '500-700$',
  },
  {
    title: 'Medium-Risk',
    description:
      'Balanced strategy trading assets like Solana, Fantom, and Livepeer, offering moderate risk with higher potential returns.',
    assets: ['BTC', 'ETH', 'SOL', 'ADA'],
    returns: '5-9',
    returnsYear: '15$',
    risk: '1000-1250$',
  },
  {
    title: 'High-Risk',
    description:
      'Aggressive strategy trading highly speculative assets such as PEPE, DOGE, NOT, SHIB, and ADA, for higher returns.',
    assets: ['PEPE', 'DOGE', 'NOT', '1000SATS', 'ADA'],
    returns: '10-15',
    returnsYear: '15$',
    risk: '2000-2500$',
  },
  {
    title: 'Ultra-Risk',
    description:
      'Bold strategy focusing on volatile assets like PEPE, DOGE, NOT, SHIB, and ADA, High trans aiming for increased returns.',
    assets: ['PEPE', 'DOGE', 'NOT', '1000SATS', 'ADA'],
    returns: '20-25',
    returnsYear: '35$',
    risk: '2500-3000$',
  },
]

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: animationData2,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptions3 = {
  loop: true,
  autoplay: true,
  animationData: animationData3,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptions4 = {
  loop: true,
  autoplay: true,
  animationData: animationData4,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Dashboard = ({ user, isAdmin }) => {
  // useStickyHeader();
  const [userInfo, setUserInfo] = useState({});
  const [robots, setRobots] = useState([]);
  const [buys, setBuys] = useState([]);
  const [currBuys, setCurrBuys] = useState([]);
  const [historyBuys, setHistoryBuys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [profit, setProfit] = useState(0);
  const [profitNew, setProfitNew] = useState({ profitToday: 0, totalProfit: 0 });
  const [selectedSymbol, setSelectedSymbol] = useState({})
  const [balances, setBalances] = useState({ 'BALANCE USDT': 0, 'TOTAL PROFIT': 0, 'TOTAL POSITION AMOUNT': 0 });
  const [avUSDT, setAvUSDT] = useState(0)
  const [selectedBot, setSelectedBot] = useState(null);
  const [showModalTelegram, setShowModalTelegram] = useState(false);
  const [showModealConfigBinance, setShowModalConifgBinance] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [changePassword, setChangePassword] = useState({ userId: user, oldPassword: "", newPassword: "", confirmNewPassword: "" });
  const [telegramGroup, setTelegramGroup] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [showActiveTrades, setShowActiveTrades] = useState(false);
  const [showHistoryTrades, setShowHistoryTrades] = useState(false);
  const [configBot, setConfigBot] = useState({ symbol: "", apiKey: "", apiSecret: "", buyAmount: 15, userId: "", marginCallLimit: 1.1 });
  const [selectedSymbols, setSelectedSymbols] = useState({
    BTCUSDT: false,
    FTMUSDT: false,
    PEPEUSDT: false,
    SHIBUSDT: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expandedBots, setExpandedBots] = useState({});
  const [expandedBuys, setExpandedBuys] = useState({});
  const [expandedHistoryBuys, setExpandedHistoryBuys] = useState({});
  const auth = useSelector((state) => state.auth);
  const [showDialogError, setShowDialogError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [imageError, setImageError] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [needSpecialAction, setNeedSpecialAction] = useState('');
  const handleCloseError = () => {
    setShowDialogError(false);
    if (needSpecialAction != '') {
      eval(needSpecialAction)
    }
    setNeedSpecialAction('')
  }
  //dropdown-basic
  const handleShowError = (message, image, url) => {
    if (image) {
      setImageError(image)
    }
    if (url) {
      setUrlError(url)
    }
    setErrorMessage(message)
    setShowDialogError(true);
  }

  const handleGoTo = () => {
    // Navigate to another page or perform an action
    navigate(urlError)
    console.log('Navigating...');
    handleCloseError();
  };

  useEffect(() => {
    if (auth.user) {
      setIsLoading(true)
      fetchEarnings();
      fetchCurrentBuys();
      fetchHistoryBuys(0);
      fetchAllSells();
    } else {
      navigate('/home');
    }
    if (!auth.isAdmin) {
      localStorage.removeItem('nimdasi')
    }
  }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => { fetchCurrentBuys(); }, 10000);
    return () => clearInterval(intervalId);
  }, []);
  const handleRefresh = () => {
    window.location.reload()
  }

  const fetchEarnings = async () => {
    let result = await dispatch(fetchBots({ userId: user }));
    helper.checkForResponse(result, function (canContinue, result) {
      if (canContinue) {
        setRobots(result.newArray);
        setUserInfo({ user: result.user, subscription: result.subscription })
        if (result.user.allBalances) {
          setAvUSDT(parseFloat(result.user.allBalances.balances.filter(f => f.asset == 'USDT')[0].free).toFixed(2))
        }
        setProfitNew(result.profit)
        let mutableBuys = [...result.newArray];
        let sorted = mutableBuys.sort((a, b) => {
          if (a.balanceUSDT > b.balanceUSDT) return -1;
        });
        balances['BALANCE USDT'] = parseFloat(sorted[0] && sorted[0].balanceUSDT);
        setBalances(balances);
        let newObj = {};
        result.newArray.map((value) => {
          newObj[value.symbol] = true;
        });
        setSelectedSymbols(newObj);
        setIsLoading(false)
      } else {
        // console.log(result.error)
        handleShowError(result.error.message)
      }
    })

  };

  const fetchCurrentBuys = async () => {
    let result = await dispatch(getAllBuys({ userId: user }));
    helper.checkForResponse(result, function (canContinue, result) {
      let buys = result.buys;
      let arr = [];
      let sumPositionAmount = 0;
      buys.map((value) => {
        if (arr.findIndex(f => f.symbol == value.symbol) == -1) {
          let currObj = {
            symbol: value.symbol,
            positionAmount: value.currentPrice * value.buyAmount,
            numbersOfCallMargin: value.consecutiveNumber,
            currentPrice: value.currentPrice,
            callMargin: value.initialPrice - (value.initialPrice * (value.marginBuy ? value.marginBuy : 0.5) / 100),
            takeProfit: value.initialPrice + ((value.initialPrice * value.marginCallLimit) / 100),
            initialBuy: value.initialPrice
          };
          sumPositionAmount += value.currentPrice * value.buyAmount;
          arr.push(currObj);
        } else {
          let curr = arr.filter(f => f.symbol == value.symbol)[0];
          curr.positionAmount += value.currentPrice * value.buyAmount;
          sumPositionAmount += value.currentPrice * value.buyAmount;
          if (value.consecutiveNumber > curr.numbersOfCallMargin) {
            curr.callMargin = value.initialPrice - (value.initialPrice * (value.marginBuy ? value.marginBuy : 0.5) / 100);
            curr.takeProfit = value.initialPrice + ((value.initialPrice * value.marginCallLimit) / 100);
            curr.initialBuy = value.initialPrice;
          }
          curr.numbersOfCallMargin = value.consecutiveNumber > curr.numbersOfCallMargin ? value.consecutiveNumber : curr.numbersOfCallMargin;
        }
      });
      setBuys(arr);
      balances['TOTAL POSITION AMOUNT'] = sumPositionAmount;
      setBalances(balances);
      let mutableBuys = [...buys];
      let sorted = mutableBuys.sort((a, b) => {
        if (a.symbol < b.symbol) return -1;
        if (a.symbol > b.symbol) return 1;
        return 0;
      });
      setCurrBuys(sorted);
    })
  };

  const fetchHistoryBuys = async () => {
    let result = await dispatch(getHistoryBuys({ userId: user, inititalDays: 0 }));
    let buys = result.payload.result.data;
    let mutableBuys = [...buys];
    let currData = mutableBuys.sort((a, b) => {
      return new Date(b._id) - new Date(a._id);
    });
    setHistoryBuys(currData);
  };
  const getMoreHistory = async (inititalDays) => {
    let result = await dispatch(getHistoryBuys({ userId: user, inititalDays }));
    let buys = result.payload.result.data;
    return buys
  };

  const fetchAllSells = async () => {
    let result = await dispatch(getAllSells({ userId: user }));
    setProfit(result.payload.result.currObj);
    balances['TOTAL PROFIT'] = result.payload.result.sum;
    setBalances(balances);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    navigate('/login');
  };

  const handleCreateRobot = async () => {
    let currConfig = configBot;
    let currBot = robots.length > 0 ? robots[0] : {}
    if (currBot) {
      currConfig.apiKey = userInfo.user.apiKey
      currConfig.apiSecret = userInfo.user.apiSecret
      if (currConfig.buyAmount == '') currConfig.buyAmount = currBot.buyAmount
      if (currConfig.marginCallLimit == '') currConfig.marginCallLimit = currBot.marginCallLimit
    }
    currConfig.symbol = currConfig.symbol.toUpperCase()
    currConfig.userId = user;
    let result = await dispatch(createBot(currConfig));
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        fetchEarnings();
        setShowModal(false);
      } else {
        handleShowError(message)
      }
    })
  };

  const handleEditBot = async () => {
    let currConfig = configBot;
    if (!currConfig.apiKey) currConfig.apiKey = selectedBot.apiKey;
    if (!currConfig.apiSecret) currConfig.apiSecret = selectedBot.apiSecret;
    if (!currConfig.buyAmount) currConfig.buyAmount = selectedBot.buyAmount;
    if (!currConfig.marginCallLimit) currConfig.marginCallLimit = selectedBot.marginCallLimit;
    currConfig.userId = user;
    currConfig.botId = selectedBot._id;
    let result = await dispatch(editBot(currConfig));
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        fetchEarnings();
        setShowModalEdit(false)
        setSelectedBot(null)
        handleShowError(message)
      } else {
        handleShowError(result.error.message)
      }
    })
  };

  const handleChangePassword = (e) => {
    changePassword[e.target.name] = e.target.value;
    setChangePassword(changePassword);
  };

  const handleCheckboxChange = (symbol) => {
    setSelectedSymbols({
      ...selectedSymbols,
      [symbol]: !selectedSymbols[symbol],
    });
  };

  const handleConfigChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let config = configBot;
    config[name] = value;
    setConfigBot(config);
  };
  const handleArrConfigChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let config = { ...selectedBot }; // Create a shallow copy of selectedBot
    let margin = { ...config.marginConfiguration }; // Create a shallow copy of marginConfiguration

    // Ensure the margin object you want to update is a new copy
    let updatedMargin = { ...margin[name], marginBuy: Number(value) };

    // Assign the updated margin object back to the config
    margin[name] = updatedMargin;
    config.marginConfiguration = Object.values(margin);

    setConfigBot(config);
  };

  const addTelegramGroup = async () => {
    let result = await dispatch(saveTelegramGroup({ telegramGroup, userId: user }));
    // console.log(result)
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        handleShowError(message);
        setShowModalTelegram(false)
      } else {
        handleShowError(result.error.message)
      }
    })
  };
  const addConfigBinance = async () => {
    let result = await dispatch(addConfigBinanceSlice({ apiKey, apiSecret, userId: user }));
    console.log(result)
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        fetchEarnings();
        handleShowError(message);
        setShowModalConifgBinance(false)
      } else {
        handleShowError(result.error.message)
      }
    })
  };

  const addChangePassword = async () => {
    let result = await dispatch(saveChangePassword(changePassword));
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        handleLogout();
      } else {
        handleShowError(result.error.message)
      }
    })
  };

  const stopBuy = async (row) => {
    let result = await dispatch(changeStopBuy({ userId: user, botId: row._id }));
    helper.checkForResponse(result, function (canContinue, result, message) {
      if (canContinue) {
        handleShowError(message);
        fetchEarnings();
      } else {
        handleShowError(result.error.message)
      }
    })
  };

  const deleteRobotFunc = (robot) => {
    let check = window.confirm("Are you sure")
    if (check) {

      let check2 = window.confirm(`If you have currently active transactions for this token (${robot.symbol}) that are being managed by the bot. If you delete them from the bot, you will need to manually sell everything you have of this token if you choose to. Be aware that once you delete these transactions, the bot will no longer manage or sell them on your behalf.`)
      if (check2) {
        dispatch(deleteRobot(robot)).then(result => {
          helper.checkForResponse(result, function (canContinue, result, message) {
            if (canContinue) {
              handleShowError(message);
              fetchEarnings();
            } else {
              handleShowError(result.error.message)
            }
          })
        });
      }
    }
  };

  const sellAllFunc = (robot) => {
    let check = window.confirm('are you sure?')
    robot.userId = user
    robot.transId = null
    if (check) {
      dispatch(sellAll(robot)).then(result => {
        helper.checkForResponse(result, function (canContinue, result, message) {
          if (canContinue) {
            handleShowError(message);
          } else {
            handleShowError(result.error.message)
          }
        })
      });
    }
  };
  const sellSpecificTrans = (transId) => {
    let check = window.confirm('are you sure?')
    let robot = robots.filter(f => f.symbol == transId.symbol)[0]
    robot = { ...robot }
    robot.userId = user
    robot.transId = transId._id
    if (check) {
      dispatch(sellAll(robot)).then(result => {
        helper.checkForResponse(result, function (canContinue, result, message) {
          if (canContinue) {
            handleShowError(message);
          } else {
            handleShowError(result.error.message)
          }
        })
      });
    }
  };
  const removeSpecificTrans = (transId) => {
    let check = window.confirm('are you sure?')
    let robot = robots.filter(f => f.symbol == transId.symbol)[0]
    robot = { ...robot }
    robot.userId = user
    robot.transId = transId._id
    if (check) {
      dispatch(removeAll(robot)).then(result => {
        helper.checkForResponse(result, function (canContinue, result, message) {
          if (canContinue) {
            handleShowError(message);
          } else {
            handleShowError(result.error.message)
          }
        })
      });
    }
  };

  const startRobotFunc = (robot) => {
    dispatch(startRobot(robot)).then(result => {
      helper.checkForResponse(result, function (canContinue, result, message) {
        if (canContinue) {
          handleShowError(message);
          fetchEarnings()
        } else {
          handleShowError(result.error.message)
        }
      })
    });
  };
  const buyBnb = async () => {
    let confirm = window.confirm('Are you sure to buy 5$ BNB?')
    if (confirm) {
      let result = await dispatch(buyBnbSlice({ userId: auth.user }))
      helper.checkForResponse(result, function (canContinue, result, message) {
        if (canContinue) {
          handleShowError(message);
        } else {
          handleShowError(result.error.message)
        }
      })
    }
  }

  const toggleExpandedBot = (id) => {
    setExpandedBots({
      ...expandedBots,
      [id]: !expandedBots[id],
    });
  };

  const toggleExpandedBuy = (id) => {
    setExpandedBuys({
      ...expandedBuys,
      [id]: !expandedBuys[id],
    });
  };

  const toggleExpandedHistoryBuy = (id) => {
    setExpandedHistoryBuys({
      ...expandedHistoryBuys,
      [id]: !expandedHistoryBuys[id],
    });
  };

  const clearActiveTrans = () => {
    setSelectedSymbol({})
    setShowActiveTrades(false)
    setShowHistoryTrades(false)
  }
  const renderBuys = () => {
    return (
      <Row>
        {robots.map(rob => {
          let row = buys.filter(f => f.symbol == rob.symbol)[0]
          return (
            <Col key={rob.symbol} sm={12} md={6} lg={6}>
              <Card className='mb-3'>
                {rob.isStarted ? <div className="flashing-dot-green"><Lottie options={defaultOptions} height={40} width={40} /></div> : <div className="flashing-dot-red"><Lottie options={defaultOptions2} height={40} width={40} /></div>}
                {rob.needToBuy ? <div className="flashing-dot-green2"><Lottie options={defaultOptions3} height={40} width={40} /></div> : <div className="flashing-dot-red2"><Lottie options={defaultOptions4} height={40} width={40} /></div>}
                <Card.Body>
                  <Card.Title>{rob.symbol}</Card.Title>
                  <Card.Text>
                    <div className='flex justify-content-start'>
                      <span className=''>Price:</span>
                      <span className='mx-2 font-bold'>{row && row.currentPrice}</span>
                    </div>
                    <div className='flex justify-content-start'>
                      <span className=''>Call Margin:</span>
                      <span className='mx-2 font-bold'>{row && row.numbersOfCallMargin}</span>
                    </div>
                    <div className='flex justify-content-start'>
                      <span className=''>Profit:</span>
                      <span className='mx-2 font-bold'>
                        {profit && profit[rob.symbol] ? (
                          `${profit[rob.symbol].toFixed(2)}$`
                        ) : (
                          <span>
                            <span className="loading-animation"></span>
                          </span>
                        )}
                      </span>                    </div>
                    <div className='flex justify-content-start'>
                      <span className=''>Profit Trigger Price:</span>
                      <span className='mx-2 font-bold'>{calculateProfit(currBuys, rob.symbol)}%</span>
                    </div>
                  </Card.Text>
                  {expandedBuys[row && row.symbol] && (
                    <>
                      <div className='flex justify-content-start'>
                        <span className=''>Amount:</span>
                        <span className='mx-2 font-bold'>{row && row.positionAmount.toFixed(4)}$</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Margin Trigger Price:</span>
                        <span className='mx-2 font-bold'>{row && row.callMargin.toFixed(8)}$</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>initialBuy:</span>
                        <span className='mx-2 font-bold'>{row && row.initialBuy}$</span>
                      </div>
                    </>
                  )}
                  <div className='flex content-start'>
                    <Button
                      variant="primary"
                      onClick={() => toggleExpandedBuy(rob.symbol)}
                      className='mr-1'
                    >
                      {expandedBuys[rob.symbol] ? "Show Less" : "Show More"}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSelectedSymbol(rob);
                        let history = currBuys.filter(f => f.symbol == rob.symbol)
                        if (history.length > 0) {
                          setShowActiveTrades(true); document.getElementById('init-point').scrollIntoView()
                        }
                      }}
                    >
                      Show ({row && row.numbersOfCallMargin || 0})
                    </Button>
                    <Dropdown className='mx-1'>
                      <Dropdown.Toggle id="dropdown-basic">
                        Actions
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => { startRobotFunc(rob) }}>{rob.isStarted ? "Stop Robot" : "Start Robot"}</Dropdown.Item>
                        <Dropdown.Item onClick={() => { deleteRobotFunc(rob) }}>Delete</Dropdown.Item>
                        <Dropdown.Item onClick={() => { setSelectedBot(rob); setShowModalEdit(true) }}>Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => { stopBuy(rob) }}>{rob.needToBuy ? "Stop Buy" : "Enable Buy"}</Dropdown.Item>
                        <Dropdown.Item onClick={() => { sellAllFunc(rob) }}>Sell All</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    );
  };

  const renderHistoryTradesChild = (data) => {
    let currData = data.data;
    return (
      <div key={data._id}>
        <div className='d-flex justify-content-between'>
          <h3>{data._id}</h3>
          <h3>PROFIT: {data.profit.toFixed(2)}$</h3>
        </div>
        <Row>
          {currData.map(row => (
            <Col key={row._id} sm={12} md={6} lg={4}>
              <Card className='mb-3'>
                <Card.Body>
                  <Card.Title>{row.responseSellFromBinance.symbol}</Card.Title>
                  <Card.Text>
                    Initial Buy: {row.initialPrice}
                    <br />
                    Profit: {row.profitTrans.toFixed(2)}$
                  </Card.Text>
                  {expandedHistoryBuys[row._id] && (
                    <>
                      <Card.Text>Date: {new Date(parseInt(row.date2)).toLocaleDateString()} {new Date(parseInt(row.date2)).toLocaleTimeString()}</Card.Text>
                      <Card.Text>Current Price: {row.currentPrice}</Card.Text>
                    </>
                  )}
                  <Button
                    variant="primary"
                    onClick={() => toggleExpandedHistoryBuy(row._id)}
                  >
                    {expandedHistoryBuys[row._id] ? "Show Less" : "Show More"}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  const renderHistoryTrades = () => {
    let mutableBuys = [...historyBuys];
    let currData = mutableBuys.sort((a, b) => {
      return new Date(b._id) - new Date(a._id);
    });
    return currData.map((value) => {
      return renderHistoryTradesChild(value);
    });
  };

  const renderModalCreateBot = () => {

    return (
      <Modal id='modal' size="lg" show={showModal} onHide={() => { setShowModal(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Crypto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'Symbol'}</Form.Label>
            <Form.Control
              name="symbol"
              placeholder='example: BTCUSDT'
              onChange={handleConfigChange}
            />
            <Form.Label>{'API KEY'}</Form.Label>
            <Form.Control
              name="apiKey"
              onChange={handleConfigChange}
              defaultValue={userInfo.user && userInfo.user.apiKey}
            />
            <Form.Label>{'API SECRET'}</Form.Label>
            <Form.Control
              name="apiSecret"
              onChange={handleConfigChange}
              defaultValue={userInfo.user && userInfo.user.apiSecret}
            />
            <Form.Label>{'Buy Amount'}</Form.Label>
            <Form.Control
              name="buyAmount"
              placeholder='15 by default'
              onChange={handleConfigChange}
              defaultValue={robots.length > 0 ? robots[0].buyAmount : ''}
            />
            <Form.Label>{'Margin'}</Form.Label>
            <Form.Control
              name="marginCallLimit"
              placeholder='1.1% by deault'
              onChange={handleConfigChange}
              defaultValue={robots.length > 0 ? robots[0].marginCallLimit : ''}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModal(false) }}>Close</Button>
          {selectedBot ? <Button variant="primary" onClick={handleEditBot}>Save</Button> : <Button variant="primary" onClick={handleCreateRobot}>Create</Button>}
        </Modal.Footer>
      </Modal>
    );
  };
  const renderModalEditBot = () => {
    return (
      <Modal id='modal' size="lg" show={showModalEdit} onHide={() => { setShowModalEdit(false); setSelectedBot(null) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Crypto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'Symbol'}</Form.Label>
            <Form.Control
              name="symbol"
              placeholder='BTCUSDT'
              onChange={handleConfigChange}
              defaultValue={selectedBot && selectedBot.symbol}
            />
            <Form.Label>{'API KEY'}</Form.Label>
            <Form.Control
              name="apiKey"
              placeholder='asdasdasdas'
              onChange={handleConfigChange}
              defaultValue={selectedBot && selectedBot.apiKey}
            />
            <Form.Label>{'API SECRET'}</Form.Label>
            <Form.Control
              name="apiSecret"
              placeholder='asdsadasdas'
              onChange={handleConfigChange}
              defaultValue={selectedBot && selectedBot.apiSecret}
            />
            <Form.Label>{'Buy Amount'}</Form.Label>
            <Form.Control
              name="buyAmount"
              placeholder='15 by default'
              onChange={handleConfigChange}
              defaultValue={selectedBot && selectedBot.buyAmount}
            />
            <Form.Label>{'Margin'}</Form.Label>
            <Form.Control
              name="marginCallLimit"
              placeholder='1.1% by deault'
              onChange={handleConfigChange}
              defaultValue={selectedBot && selectedBot.marginCallLimit}
            />
            {selectedBot && selectedBot.marginConfiguration.length && selectedBot.marginConfiguration.map((value, index) => {
              if (index < selectedBot.marginConfiguration.length - 1) {
                return (
                  <>
                    <Form.Label>{`Margin Number ${value.marginNumber}`}</Form.Label>
                    <Form.Control
                      name={value.marginNumber}
                      onChange={handleArrConfigChange}
                      defaultValue={value.marginBuy}
                    />
                  </>
                )
              }
            })}
            {/* "marginNumber": 1,
      "marginBuy": 0.5,
      "quatityToBuy": 1,
      "needToBuy": true */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalEdit(false); setSelectedBot(null) }}>Close</Button>
          {selectedBot ? <Button variant="primary" onClick={handleEditBot}>Save</Button> : <Button variant="primary" onClick={handleCreateRobot}>Create</Button>}
        </Modal.Footer>
      </Modal>
    );
  };

  const renderModalAddTelegram = () => {
    return (
      <Modal id='modal' size="lg" show={showModalTelegram} onHide={() => { setShowModalTelegram(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Telegram</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'Telegram Group'}</Form.Label>
            <Form.Control
              name="telegram"
              placeholder='emaple: @zhekoykrasi'
              onChange={(e) => { setTelegramGroup(e.target.value) }}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalTelegram(false) }}>Close</Button>
          <Button variant="primary" onClick={addTelegramGroup}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const renderModalAddConfigBinance = () => {
    return (
      <Modal id='modal' size="lg" show={showModealConfigBinance} onHide={() => { setShowModalConifgBinance(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add API Key/Secret</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'API KEY'}</Form.Label>
            <Form.Control
              name="apiKey"
              placeholder='Example: hiuashfuaigfhbasy'
              onChange={(e) => { setApiKey(e.target.value) }}
              defaultValue={userInfo.user && userInfo.user.apiKey}
            />
          </Form>
          <Form>
            <Form.Label>{'API SECRET'}</Form.Label>
            <Form.Control
              name="apiSecret"
              placeholder='Example: uaskhfousahfuasfhuasj'
              onChange={(e) => { setApiSecret(e.target.value) }}
              defaultValue={userInfo.user && userInfo.user.apiSecret}
            />
          </Form>
          {/* Instructional Text */}
          <div className="mt-4">
            <p>
              Need help creating your API key and secret? Follow the steps in this guide:
              <a href="https://www.binance.com/en/support/faq/360002502072" target="_blank" rel="noopener noreferrer">
                How to create Binance API Key & Secret
              </a>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalConifgBinance(false) }}>Close</Button>
          <Button variant="primary" onClick={addConfigBinance}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderModalChangePassword = () => {
    return (
      <Modal id='modal' size="lg" show={showModalChangePassword} onHide={() => { setShowModalChangePassword(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'Old Password'}</Form.Label>
            <Form.Control
              name="oldPassword"
              placeholder=''
              onChange={handleChangePassword}
            />
            <Form.Label>{'New Password'}</Form.Label>
            <Form.Control
              name="newPassword"
              placeholder=''
              onChange={handleChangePassword}
            />
            <Form.Label>{'Confirm New Password'}</Form.Label>
            <Form.Control
              name="confirmNewPassword"
              placeholder=''
              onChange={handleChangePassword}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalChangePassword(false) }}>Close</Button>
          <Button variant="primary" onClick={addChangePassword}>Change</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const calculateProfit = (currBuysCu, symbol) => {
    let sumbolBuys = currBuysCu.filter(f => f.symbol == symbol)
    let mutableBuys = [...sumbolBuys];
    let sorted = mutableBuys.sort((a, b) => {
      if (a.consecutiveNumber > b.consecutiveNumber) return -1;
    });
    let initialPrice = sorted[0] ? sorted[0].initialPrice : 0
    const diff = (sorted[0] ? sorted[0].currentPrice : 0) - initialPrice
    const percentageDrop = ((diff) / initialPrice) * 100;
    let color = 'greenPercent'
    if (percentageDrop < 0) {
      color = 'redPercent'
    }
    return (<span className={color}>{percentageDrop && percentageDrop.toFixed(3)}</span>)
  }

  const printSubscription = () => {
    if (userInfo.subscription && parseInt(userInfo.subscription.endDate) > parseInt(new Date().getTime())) {
      return userInfo.subscription ? new Date(parseInt(userInfo.subscription.endDate)).toLocaleString() : null
    } else if (!userInfo.subscription) {
      return 'NOT ACTIVE SUBSCRIPTION'
    } else {
      return 'EXPIRED'
    }
  }

  const renderMethod = () => {
    if (isLoading) {
      return (
        <>
          <div className='d-flex justify-content-center my-2'>
            <bold>Loading Tokens</bold>
          </div>
          <div className='d-flex justify-content-center'>
            <div className="loader"></div>
          </div>
        </>

      )
    } else {
      if (showActiveTrades) {
        return (<DetailedTrans sell={sellSpecificTrans} remove={removeSpecificTrans} clear={clearActiveTrans} buys={currBuys.filter(f => f.symbol == selectedSymbol.symbol).reverse()}></DetailedTrans>)
      } else if (showHistoryTrades) {
        return (<History clear={clearActiveTrans} history={historyBuys} getMoreHistory={getMoreHistory}></History>)
      } else if (robots.length > 0) {
        return renderBuys()
      } else {
        return renderNoRobots()
      }
    }
  }
  const renderDisclaimer = () => {
    try {
      if (userInfo && userInfo.user && userInfo.user.allBalances && userInfo && userInfo.user && userInfo.user.allBalances.balances.length > 0 && userInfo.user.allBalances.balances.filter(f => f.asset == 'BNB')[0].free < 0.0002) {
        return (<div id="exx" className="exclaimer">
          Important: If you don't have BNB, the bot will not buy anything. After buying BNB, you need to manually start the bot by clicking on the 'Actions' button.
          <button className="close-btn" onClick={() => {
            document.getElementById('exx').style.display = "none"
          }}>&times;</button>
        </div>)
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }

  const renderNoRobots = () => {
    return (
      <>
        <div className='d-flex justify-content-center my-4'>
          <Button variant="primary" className='btn-special-cry' onClick={() => { navigate('/pricing') }} >Get Your Subscription</Button>
        </div>
      </>
    )
  }
  const renderActions = () => {
    // console.log(userInfo)
    if (userInfo.subscription) {
      return (<Dropdown.Menu>
        <Dropdown.Item onClick={() => { setShowModal(true) }}>Add Symbol</Dropdown.Item>
        <Dropdown.Item onClick={() => { setShowModalTelegram(true) }}>Add Telegram</Dropdown.Item>
        <Dropdown.Item onClick={() => { setShowModalConifgBinance(true) }}>{userInfo.user && userInfo.user.apiKey ? 'Edit' : 'Add'} Api Key/Secret</Dropdown.Item>
        <Dropdown.Item onClick={() => { setShowModalChangePassword(true) }}>Change Password</Dropdown.Item>
        {userInfo.user.apiKey ? <Dropdown.Item onClick={() => { buyBnb(true) }}>Buy 5$ BNB</Dropdown.Item> : null}
      </Dropdown.Menu>)
    } else {
      return (<Dropdown.Menu>
        <Dropdown.Item onClick={() => { setShowModalTelegram(true) }}>Add Telegram</Dropdown.Item>
        <Dropdown.Item onClick={() => { setShowModalConifgBinance(true) }}>Add Api Key/Secret</Dropdown.Item>
        <Dropdown.Item onClick={() => { setShowModalChangePassword(true) }}>Change Password</Dropdown.Item>
      </Dropdown.Menu>)
    }
  }
  const selectStrategy = (currentStrategy) => {
    console.log(userInfo)
    if (userInfo.subscription) {
      if (robots.length > 0) {
        setNeedSpecialAction(`document.getElementById('logo').scrollIntoView(); setTimeout(() => {
          document.getElementById('dropdown-basic').click();
        },500)`)
        handleShowError(`You have to delete existing robots`)
      } else {
        let check = window.confirm(`Are you sure that you want to apply this strategy?  ${currentStrategy.title}`)
        if (check) {
          if (!userInfo.user.apiKey || !userInfo.user.apiSecret) {
            handleShowError("Missing API credentials: Please provide both an API Key and API Secret. To proceed, go to the top left corner, click the 'Actions' button, and then add the API Key and Secret.");
          } else {
            dispatch(setupStrategy({ userId: user, strategyType: currentStrategy.title })).then(result => {
              helper.checkForResponse(result, function (canContinue, result, message) {
                if (canContinue) {
                  handleShowError(message);
                  fetchEarnings();
                } else {
                  handleShowError(message)
                }
              })
            });
          }
        }
      }
    } else {
      handleShowError(`First, you have to subscribe. Press button Go`, null, '/pricing')
    }
  }
  const renderStrategies = () => {
    return (
      <div className="App22">
        <h2>Strategies</h2>
        <div className="wrapper">
          {strategies.map((strategy, index) => (
            <StrategyCard key={strategy.returns} strategy={strategy} selectStrategy={selectStrategy}></StrategyCard>
          ))}
        </div>
      </div>
    )
  }

  return (
    <Container style={{ marginBottom: '5%' }}>
      <ReactPullToRefresh onRefresh={handleRefresh}>
        <h2 id="init-point" className='text-center'>Crypto Dashboard</h2>
        {renderDisclaimer()}
        <Row className="justify-content-between my-4">
          <div className='flex justify-content-between'>
            <Dropdown className="">
              <Dropdown.Toggle variant="primary" className='btn-special-cry' id="dropdown-basic">
                Actions
              </Dropdown.Toggle>
              {renderActions()}
            </Dropdown>
            {userInfo.subscription && historyBuys.length > 0 ? <Button onClick={() => { setShowHistoryTrades(true) }} disabled={historyBuys.length == 0} variant="primary" className='btn-special-cry'>{historyBuys.length == 0 ? 'Loading..' : 'History'}</Button> : null}
          </div>
          <div className='mt-4'>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>BALANCE USDT:</span>
              <span className='bold-span ml-2'>{avUSDT}$</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL POSITION AMOUNT:</span>
              <span className='bold-span ml-2'>{balances && balances['TOTAL POSITION AMOUNT'].toFixed(2)}$</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL PROFIT TODAY:</span>
              <span className='bold-span ml-2'>{profitNew ? profitNew.profitToday.toFixed(2) : 0}$</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL PROFIT:</span>
              <span className='bold-span ml-2'>{profitNew ? profitNew.totalProfit.toFixed(2) : 0}$</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>SUBSCRIPTION EXPIRE</span>
              <span className='bold-span ml-2'>{printSubscription()}</span>
            </div>
          </div>

        </Row>
        {renderMethod()}
        {renderStrategies()}
        {renderModalCreateBot()}
        {renderModalEditBot()}
        {renderModalAddTelegram()}
        {renderModalAddConfigBinance()}
        {renderModalChangePassword()}
        {<DialogError
          show={showDialogError}
          handleClose={handleCloseError}
          message={errorMessage}
          isInfo={true}
          imageUrl={imageError}
          goToAction={handleGoTo}
          goToActionBool={urlError}
        >
        </DialogError>}
      </ReactPullToRefresh>
    </Container >
  );
};

export default Dashboard;
