import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';


const AdminUsers = ({ setCurrUser, clear, buys, deleteUser, setSubscription, checkBalances }) => {
  const [showModalSubscription, setShowModalSubscription] = useState(false)
  const [showModalUser, setShowModalUser] = useState(false)
  const [plan, setPlan] = useState(0)
  const [period, setPeriod] = useState(30)
  const [selectedUser, setSelectedUser] = useState('')
  const [selectedInfoUser, setSelectedInfoUser] = useState(JSON.stringify({}, null, 2))
  const [expandedBuys, setExpandedBuys] = useState({});

  const setThisUser = (id) => {
    setCurrUser(id)
  }

  const addSubscription = (id) => {
    setSelectedUser(id)
    setShowModalSubscription(true)
  }
  const toggleExpandedBuy = (id) => {
    setExpandedBuys({
      ...expandedBuys,
      [id]: !expandedBuys[id],
    });
  };
  const checkBalancesInternal = async (userId) => {
    let result = await checkBalances(userId)
    setSelectedInfoUser(JSON.stringify(result, null, 2))
    setShowModalUser(true)
  }


  const renderModalAddTelegram = () => {
    return (
      <Modal id='modal' size="lg" show={showModalSubscription} onHide={() => { setShowModalSubscription(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>{'Subscription'}</Form.Label>
            <Form.Control
              name="plan"
              placeholder='2 | 5 | 10 | 20'
              onChange={(e) => { setPlan(e.target.value) }}
            />
          </Form>
          <Form>
            <Form.Label>{'Period'}</Form.Label>
            <Form.Control
              name="period"
              placeholder='30 | 365'
              onChange={(e) => { setPeriod(e.target.value) }}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalSubscription(false) }}>Close</Button>
          <Button variant="primary" onClick={() => { setSubscription(selectedUser, plan, period); setTimeout(() => { setShowModalSubscription(false) }, 500) }}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const renderModalShowProperties = () => {
    return (
      <Modal id='modal' size="lg" show={showModalUser} onHide={() => { setShowModalUser(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Check User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{selectedInfoUser}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalUser(false) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  if (buys.length > 0) {
    // console.log(buys)
    return (
      <Row>
        {/* <div className='flex justify-start mb-2'>
          <Button className='btn btn-special' onClick={() => { clear() }}>Back</Button>
        </div> */}
        {buys.map(row => (
          <Col key={row.date} sm={12} md={6} lg={6}>
            <Card className='mb-3'>
              <Card.Body>
                <Card.Title>{row.symbol}</Card.Title>
                <Card.Text>
                  <div className='flex justify-content-start'>
                    <span className=''>Email:</span>
                    <span className='mx-2 font-bold'>{row.email}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Subscription:</span>
                    <span className='mx-2 font-bold'>{row.hasSubscription.toString()}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Number of Bots:</span>
                    <span className='mx-2 font-bold'>{row.bots.length}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>User ID:</span>
                    <span className='mx-2 font-bold'>{row._id}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Profit Today:</span>
                    <span className='mx-2 font-bold'>{row.profit ? row.profit.profitToday.toFixed(2) : 0}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Total Profit:</span>
                    <span className='mx-2 font-bold'>{row.profit ? row.profit.totalProfit.toFixed(2) : 0}</span>
                  </div>
                  {expandedBuys[row && row.email] && (
                    <>
                      <div className='flex justify-content-start'>
                        <span className=''>Request Subscription:</span>
                        <span className='mx-2 font-bold'>{row && row.isRequestedSubscription}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Number Of Bots Permited:</span>
                        <span className='mx-2 font-bold'>{row && row.numberOfBots}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Start Subscription:</span>
                        <span className='mx-2 font-bold'>{row && new Date(parseInt(row.startSubscripitonDate || 0)).toLocaleString()}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Start Subscription:</span>
                        <span className='mx-2 font-bold'>{row && new Date(parseInt(row.endSubscriptionDate || 0)).toLocaleString()}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Referred From User:</span>
                        <span className='mx-2 font-bold'>{row && row.referredFromUser || 'NOT'}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Referral Code</span>
                        <span className='mx-2 font-bold'>{row && row.referralCode || 'NOT'}</span>
                      </div>
                      <div className='flex justify-content-start'>
                        <span className=''>Binance ID</span>
                        <span className='mx-2 font-bold'>{row && row.binanceId || 'NOT'}</span>
                      </div>
                    </>
                  )}
                </Card.Text>
                <div className='flex content-start mt-2'>
                  <Button
                    variant="primary"
                    onClick={() => toggleExpandedBuy(row.email)}
                    className='mr-1'
                  >
                    {expandedBuys[row.email] ? "Show Less" : "Show More"}
                  </Button>
                  <Dropdown className=''>
                    <Dropdown.Toggle id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => { setThisUser(row._id) }}>Select</Dropdown.Item>
                      <Dropdown.Item onClick={() => { deleteUser(row._id) }}>Delete User</Dropdown.Item>
                      <Dropdown.Item onClick={() => { addSubscription(row._id) }}>Add Subscription</Dropdown.Item>
                      <Dropdown.Item onClick={() => { checkBalancesInternal(row._id) }}>Check Balances</Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => { setThisUser(row._id) }}>Stop Bots</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {renderModalAddTelegram()}
        {renderModalShowProperties()}
      </Row>

    );
  } else {
    return (null)
  }
};

export default AdminUsers;
