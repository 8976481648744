// src/Pricing.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hasDiscount } from '../redux/slices/earningsSlice';

import PricingCard from './PricingCard';
import '../css/pricing.css';
const helper = require('./../utils/helper')
let constPlans = [
  {
    plan: 'BASIC',
    price: '15',
    priceYear: '150',
    features: [{ includes: true, text: "2 Bots" }, { includes: true, text: "Estimated earning per month 5-10%" }, { includes: true, text: "Support ONLY during office hours" }, { includes: false, text: "Configure Bot strategy" }, { includes: true, text: "Reward for friend invite: 3$ per month (30$ per year subscription)" }]
  },
  {
    plan: 'ADVANCED',
    price: '25',
    priceYear: '250',
    features: [{ includes: true, text: "5 Bots" }, { includes: true, text: "Estimated earning per month 10-15%" }, { includes: true, text: "Support ONLY during office hours" }, { includes: false, text: "Configure Bot strategy" }, { includes: true, text: "Reward for friend invite: 5$ per month (50$ per year subscription)" }]
  },
  {
    plan: 'PRO',
    price: '50',
    priceYear: '400',
    features: [{ includes: true, text: "10 Bots" }, { includes: true, text: "Estimated earning per month 15-20%" }, { includes: true, text: "Support 24/7" }, { includes: true, text: "Configure Bot strategy" }, { includes: true, text: "Reward for friend invite: 10$ per month (80$ per year subscription)" }]
  },
  {
    plan: 'UNLIMITED',
    price: '80',
    priceYear: '600',
    features: [{ includes: true, text: "20 Bots" }, { includes: true, text: "Estimated earning per month +25%" }, { includes: true, text: "Support 24/7" }, { includes: true, text: "Configure Bot strategy" }, { includes: true, text: "Reward for friend invite: 16$ per month (120$ per year subscription)" }]
  }
]
const Pricing = ({ user }) => {
  const dispatch = useDispatch();
  const [userHasDiscount, setUserHasDiscount] = useState(false)
  const [plans, setPlans] = useState([]);
  React.useEffect(() => {
    console.log(user)
    if (user) {
      checkForDiscount()
    } else {
      setPlans(constPlans)
    }
  }, [user])
  const checkForDiscount = async () => {
    console.log(46)
    let result = await dispatch(hasDiscount({ userId: user }));
    console.log(result)
    helper.checkForResponse(result, function (canContinue, result) {
      console.log(result)
      if (canContinue) {
        setUserHasDiscount(result.hasDiscount)
        if (result.hasDiscount == true) {
          let newPlan = constPlans.map((value) => {
            let obj = value
            obj.discount = 50

            return obj
          })
          setPlans(newPlan)
        } else {
          setPlans(constPlans)
        }
      } else {
        alert(result.error.message)
      }
    })
  };
  return (
    <div className="App">
      <div className="wrapper">
        {plans.map((plan, index) => (
          <PricingCard key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default Pricing;
