import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Footer from '../components/Footer';
import Zigzag from './Zigzag';
import NewsLetter from './NewsLetter';

function LandingPage() {
  return (
    <div>
      <Hero />
      <Features />
      <Zigzag />
      <NewsLetter />
      <Footer />
    </div>
  );
}

export default LandingPage;
