import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Test';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import LandingPage from './components/LandingPage';
import Header from './components/Header';
import Pricing from './components/Pricing';
import Admin from './components/Admin';
import SelectUsers from './components/SelectUsers';
import ReferralProgram from './components/ReferralProgram';
import DashboardInstructions from './components/Instructions';
import ForgotPassword from './components/ForgotPassword';
import TermsOfUse from './components/Terms';
import PrivacyPolicy from './components/privacy';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState()
  const [user, setUser] = React.useState(localStorage.getItem('nigolcrypto'))
  const [isAdmin, setIsAdmin] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState('')


  React.useEffect(() => {
    let a = localStorage.getItem('nigolcrypto')
    let b = localStorage.getItem('nimdasi')
    if (a) {
      setIsLoggedIn(true)
      setUser(a)
    }
    if (b == 'eurt') {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [isLoggedIn])

  const selectUser = (userId) => {
    setSelectedUser(userId)
  }
  const handleLogin = (currUser, credentials) => {
    // Handle login logic here
    if (credentials) {
      setUser(currUser)
      setIsLoggedIn(credentials)
      let b = localStorage.getItem('nimdasi')
      if (b == 'eurt') {
        setIsAdmin(true)
      }
    }
  };
  const logout = () => {
    // Handle login logic here
    setUser(null)
    setIsAdmin(false)
  };
  const setUserToCheck = (id) => {
    setSelectedUser(id)
  }

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Header user={user} logout={logout} isAdmin={isAdmin}></Header>
          <Routes>
            <Route path="/register" element={<Register user={user} isAdmin={isAdmin}></Register>} />
            <Route path="/login" element={<Login onLogin={handleLogin} user={user} isAdmin={isAdmin}></Login>} />
            <Route path="/forgotpassword" element={<ForgotPassword></ForgotPassword>} />
            <Route path="/dashboard" element={<Dashboard user={user} isAdmin={isAdmin}></Dashboard>} />
            <Route path="/pricing" element={<Pricing user={user} isAdmin={isAdmin}></Pricing>} />
            <Route path="/howitworks" element={<DashboardInstructions user={user} isAdmin={isAdmin}></DashboardInstructions>} />
            <Route path="/nimda" element={<Admin user={selectedUser} isAdmin={isAdmin}></Admin>} />
            <Route path="/referral" element={<ReferralProgram user={selectedUser} isAdmin={isAdmin}></ReferralProgram>} />
            <Route path="/terms" element={<TermsOfUse></TermsOfUse>} />
            <Route path="/privacy" element={<PrivacyPolicy></PrivacyPolicy>} />
            <Route path="/selectusers" element={<SelectUsers user={user} isAdmin={isAdmin} setUserToCheck={setUserToCheck} isAdmin={isAdmin}></SelectUsers>} />
            <Route path="/home" element={<LandingPage user={user} isAdmin={isAdmin}></LandingPage>} />
            <Route path="/" element={<Dashboard user={user} isAdmin={isAdmin}></Dashboard>} />
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
