import React from 'react';

// Componente para mostrar cada estrategia
const StrategyCard = ({ strategy, selectStrategy }) => {
  const { title, description, assets, returns, returnsYear, risk } = strategy

  const printAssets = (assets) => {
    if (assets.length > 3) {
      return (
        <>
          <p>{assets.toString()}</p>
        </>
      )
    } else {
      return assets.toString()
    }
  }
  return (
    <div className="pricing-table gprice-single">
      <div className="head">
        <h4 className="title">{title}</h4>
      </div>
      <div className="content">
        <div className="price">
          <>
            <h1>{returns}%</h1>
            <h3>{returnsYear}<span id="price-span">/TRANS</span></h3>
          </>
        </div>
        <ul>
          Recommended balance in Binance <b>{risk}</b>
        </ul>
        <ul>
          {printAssets(assets)}
          {/* <div className='row'>
            {assets.map((value) => {
              return (<div className='col-4'>
                {value}
              </div>)
            })}
          </div> */}
        </ul>
        <ul>
          {description}
        </ul>
        <div className="sign-up">
          <button onClick={() => { selectStrategy(strategy) }} className="btn bordered radius button-cstm">Claim Now!</button>
        </div>
      </div>
    </div>
  );
};

export default StrategyCard