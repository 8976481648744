import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { register } from '../redux/slices/authSlice';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './../css/Login.css';
import { useNavigate, Link } from 'react-router-dom';
const helper = require('./../utils/helper')

const Register = ({ user }) => {
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };
  const handleSubmit = async () => {

    // Perform login logic here
    if (accepted) {
      let result = await dispatch(register({ email, password, username, referralCode }))
      helper.checkForResponse(result, function (canContinue, result, message) {
        if (canContinue) {
          alert(message)
          navigate('/login')
        } else {
          alert(result ? result.statusText : 'error')
        }
      })
    } else {
      alert(`You must accept our Terms and Conditions to continue.`)
    }
  };
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);

  return (

    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-16 pb-12 md:pt-20 md:pb-20">

          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1">Welcome to the Future of Trading</h1>
          </div>

          {/* Form */}
          <div className="max-w-sm mx-auto">
            {/* <form>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center">
                  <svg className="w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                  </svg>
                  <span className="h-6 flex items-center border-r border-white border-opacity-25 mr-4" aria-hidden="true"></span>
                  <span className="flex-auto pl-16 pr-8 -ml-16">Sign up with Google</span>
                </button>
              </div>
            </div>
          </form> */}
            {/* <div className="flex items-center my-6">
            <div className="border-t border-gray-700 border-dotted grow mr-3" aria-hidden="true"></div>
            <div className="text-gray-400">Or, register with your email</div>
            <div className="border-t border-gray-700 border-dotted grow ml-3" aria-hidden="true"></div>
          </div> */}

            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="full-name">Full Name <span className="text-red-600">*</span></label>
                <input id="full-name" type="text" className="form-input w-full text-gray-300" placeholder="First and last name" required onChange={(e) => { setUsername(e.target.value) }} />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="you@youremail.com" required onChange={(e) => { setEmail(e.target.value) }} />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-4">
              <div className="w-full px-3">
                <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Referral Code <span className="text-white-600">(optional)</span></label>
                <input id="referralCode" type="text" className="form-input w-full text-gray-300" placeholder="ABC1DEF" required onChange={(e) => { setReferralCode(e.target.value) }} />
              </div>
            </div>
            <div className='d-flex justify-content-start'>
              <div className='d-flex justify-content-center'>
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="terms-checkbox"
                    label=""
                    onChange={handleCheckboxChange}
                  />
                </Form>
                <label>Accept our <a href='/terms'>terms and conditions</a></label>
              </div>
            </div>
            <div className="text-sm text-gray-500 text-center">
              I agree to receive emails from Trader Bot about this offer as per the Trader Bot's policy. <Link href="#" className="underline text-gray-400 hover:text-gray-200 hover:no-underline transition duration-150 ease-in-out">Privacy Policy</Link>.
            </div>
            <div className="flex flex-wrap -mx-3 mt-6">
              <div className="w-full px-3">
                <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" onClick={handleSubmit}>Sign up</button>
              </div>
            </div>
            <div className="text-gray-400 text-center mt-6">
              Already using Trader Bot? <Link to="/login" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Sign in</Link>
            </div>
          </div>

        </div>
      </div>
    </section>

  );
};

export default Register;
