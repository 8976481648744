import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <footer id="footer-scroll" className="bg-gray-900 text-white py-6">
      <div className="container mx-auto px-4 text-center">
        <p>&copy; 2024 Invest Crypto. All rights reserved.</p>
      </div>
      <div className="container mx-auto px-4 text-center">
        <p style={{cursor: 'pointer'}} onClick={() => {navigate('/terms')}}>Terms</p>
        <p style={{cursor: 'pointer'}} onClick={() => {navigate('/privacy')}}>Privacy</p>
      </div>
    </footer>
  );
};

export default Footer;
