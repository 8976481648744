import React, { useState } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FeatImage01 from './public/images/referral.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/ReferralProgram.css'; // Ensure this CSS file exists

const ReferralProgram = () => {
  const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: paragraphRef, inView: paragraphInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: image1Ref, inView: image1InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const [referralCode, setReferralCode] = useState('')
  React.useEffect(() => {
    let code = localStorage.getItem('userInfo')
    if (code) {
      setReferralCode(JSON.parse(code).referralCode)
    }
  }, [])
  return (
    <Container fluid className="">
      <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
        <motion.h1
          ref={headingRef}
          className="h1 mb-4"
          initial={{ opacity: 0, y: 50 }}
          animate={headingInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
        >
          Referral Program
        </motion.h1>
        <motion.p
          ref={paragraphRef}
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          🎉 Earn Big with Referrals! 🎉
        </motion.p>
        <motion.p
          ref={paragraphRef}
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          When someone registers using your referral code, you will automatically receive 20% of their subscription amount in your Binance account every time they pay for any of our subscriptions! 💰 Don't miss out on this fantastic opportunity to boost your earnings effortlessly!
        </motion.p>
        <motion.span
          ref={paragraphRef}
          className="text-xm text-gray-400"
          initial={{ opacity: 0, y: 50 }}
          animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Your Referral Code
        </motion.span>
        <motion.h1
          ref={headingRef}
          className="h1 mb-4"
          initial={{ opacity: 0, y: 50 }}
          animate={headingInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
        >
          {referralCode}
        </motion.h1>
        <motion.p
          ref={paragraphRef}
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          🚀 Unlock a 7-Day Free Trial for Your Friends! 🚀
        </motion.p>
        <motion.p
          ref={paragraphRef}
          className="text-xl text-gray-400 mb-8"
          initial={{ opacity: 0, y: 50 }}
          animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Invite a friend and they’ll get exclusive access to a 7-day free trial, allowing them to explore our 2 powerful trading bots and experience the pinnacle of crypto trading like never before!
        </motion.p>
        <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
          <motion.div
            ref={image1Ref}
            className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
            initial={{ opacity: 0, x: -50 }}
            animate={image1InView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.5 }}
          >
            <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage01} width={540} height={405} alt="Features 01" />
          </motion.div>
        </div>
      </div>
      {/* <Row className="justify-content-center w-100">
        <Col xs={12} md={8} lg={6}>
          <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <Card className="text-center p-4 shadow-sm">
              <CSSTransition in={true} appear={true} timeout={500} classNames="fade-down">
                <Card.Body>
                  <CSSTransition in={true} appear={true} timeout={700} classNames="fade-right">
                    <Card.Title className="mb-4 display-4">Referral Program</Card.Title>
                  </CSSTransition>
                  <CSSTransition in={true} appear={true} timeout={900} classNames="fade-left">
                    <Card.Text className="lead">
                      If someone registers using your referral code, every time your referral pays for any of our subscriptions, you will automatically receive 20% of their subscription amount in your Binance account.
                    </Card.Text>
                  </CSSTransition>
                  <CSSTransition in={true} appear={true} timeout={1100} classNames="fade-up">
                    <Card.Text className="lead">
                      Additionally, the referral will receive a 10% discount on their subscription.
                    </Card.Text>
                  </CSSTransition>
                </Card.Body>
              </CSSTransition>
            </Card>
          </CSSTransition>
        </Col>
      </Row> */}
    </Container>
  );
};

export default ReferralProgram;
