import React from 'react';
import '../css/TermsOfUse.css'; // Make sure to create a corresponding CSS file for styling

const TermsOfUse = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Use for Invest Crypto Bot</h1>

      <h2>1. Introduction</h2>
      <p>Invest Crypto Bot is a cryptocurrency trading bot.</p>
      <p>By accessing and using Invest Crypto Bot, the user agrees to be bound by these terms and conditions, which constitute a legally binding agreement between the user and Invest Crypto Bot.</p>
      <p>Invest Crypto Bot reserves the right to modify these terms and conditions at any time, with notice to the user. Continued use of Invest Crypto Bot after any modifications indicates acceptance of the modified terms.</p>

      <h2>2. Use of Service</h2>
      <p>The Invest Crypto Bot service allows users to access and use the Invest Crypto Bot software through a subscription service.</p>
      <p>Users are responsible for ensuring their use of the service complies with all applicable laws and regulations.</p>
      <p>Invest Crypto Bot and Invest Crypto Bot do not provide financial, investment, legal, tax, or any other professional advice. Using the Invest Crypto Bot software involves significant risks and potential for financial loss.</p>
      <p>Users may publish and update their own trading strategies on the Invest Crypto Bot platform, and sell access to their strategies through subscriptions. Invest Crypto Bot retains the intellectual property of any strategy published on the platform.</p>

      <h2>3. Registration and Accounts</h2>
      <p>Users must create a user account to use the Invest Crypto Bot services. Users are responsible for maintaining the confidentiality of their account and password.</p>
      <p>Accurate and complete information must be provided during the registration process and kept up to date. Invest Crypto Bot reserves the right to refuse registration or cancel an account if the information provided is false, inaccurate, or incomplete.</p>

      <h2>4. Subscription</h2>
      <p>To access the trading strategies and hosting services offered by Invest Crypto Bot, users may be required to pay a subscription fee, billed on a recurring basis according to the selected plan.</p>
      <p>Users may upgrade or downgrade their subscription plan at any time, with changes reflected in the next billing cycle.</p>
      <p>Invest Crypto Bot reserves the right to change subscription fees at any time, with or without notice to the user.</p>

      <h2>5. Payment Options</h2>
      <p>Users can pay directly using their credit cards or cryptocurrency to access the services offered by Invest Crypto Bot.</p>
      <p>The user is responsible for ensuring their payment information is up to date and accurate. Failure to maintain accurate payment information may result in the suspension or termination of the user's account.</p>
      <p>Invest Crypto Bot may offer users a free trial period during which they can access and use the service without incurring any charges.</p>

      <h2>6. Invest Crypto Bot Rewards Program</h2>
      <p>Invest Crypto Bot reserves the right to void, cancel, or remove any reward points or levels a user may have earned, at any time and without prior notice.</p>

      <h2>7. Account Management</h2>
      <p>Users are responsible for maintaining the confidentiality of their Invest Crypto Bot account and password.</p>
      <p>Invest Crypto Bot reserves the right to terminate or suspend the user's account if the user violates any of these terms and conditions or engages in any illegal or unauthorized activities.</p>

      <h2>8. Availability of Service</h2>
      <p>Invest Crypto Bot provides its services on a reasonable effort basis and does not guarantee the availability of its service.</p>

      <h2>9. Responsibility of User Content</h2>
      <p>Users grant Invest Crypto Bot the right to use, modify, display, distribute, and create derivative works of their User Content for providing the Invest Crypto Bot service.</p>
      <p>Users are solely responsible for the User Content they make available through the Invest Crypto Bot platform.</p>

      <h2>10. Links and Third-Party Sites</h2>
      <p>Invest Crypto Bot may contain links to third-party websites. These links are provided for convenience only, and Invest Crypto Bot has no control over third-party websites.</p>

      <h2>11. Modification of Terms and Conditions</h2>
      <p>Invest Crypto Bot reserves the right to modify these Terms and Conditions at any time. The user's continued use of Invest Crypto Bot following the posting of changes constitutes acceptance of those changes.</p>

      <h2>12. Termination of Service</h2>
      <p>Invest Crypto Bot may terminate or suspend the user's access to Invest Crypto Bot at any time and without notice for any reason.</p>

      <h2>13. Warranties and Disclaimers</h2>
      <p>Invest Crypto Bot and all associated services are provided "as is" and "as available", without warranty of any kind, either express or implied.</p>

      <h2>14. Limitation of Liability</h2>
      <p>Invest Crypto Bot and Invest Crypto Bot are not responsible for any trading-related loss or any loss caused by the use of the Invest Crypto Bot software.</p>

      <h2>15. Third-Party Rights</h2>
      <p>A contract under these terms and conditions is for the benefit of the user and Invest Crypto Bot, and is not intended to benefit or be enforceable by any third party.</p>

      <h2>16. Linking to Invest Crypto Bot</h2>
      <p>Organizations may link to Invest Crypto Bot so long as the link is not deceptive and does not falsely imply sponsorship, endorsement, or approval of the linking party and its products/services.</p>

      <h2>17. Risks Disclosure Statement</h2>
      <p>The use of Invest Crypto Bot and its services may involve certain risks and uncertainties. Users acknowledge and agree to use Invest Crypto Bot at their own risk.</p>

      <h2>18. Privacy Policy</h2>
      <p>Users are referred to the Invest Crypto Bot Privacy Policy for further information regarding the collection, use, and disclosure of their personal information.</p>

      <h2>19. Copyright Notice</h2>
      <p>All content on Invest Crypto Bot is the property of Invest Crypto Bot or its content suppliers and is protected by international copyright laws.</p>

      <h2>20. Updates</h2>
      <p>These terms and conditions were last updated on 05/08/2024.</p>
    </div>
  );
};

export default TermsOfUse;
