import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api'; // Import the Axios instance

const initialState = {
  isAuthenticated: false,
  user: null,
  status: 'idle',
  error: null,
};

// Async thunk for login
export const fetchBots = createAsyncThunk('/bots/fetch', async (userId) => {

  const response = await api.post('/fetchBots', userId); // Adjust endpoint as needed
  return response.data
});
export const createBot = createAsyncThunk('/bots/createBot', async (configBot) => {
  const response = await api.post('/createBot', configBot); // Adjust endpoint as needed
  return response.data
});
export const editBot = createAsyncThunk('/bots/editBot', async (configBot) => {
  const response = await api.post('/editBot', configBot); // Adjust endpoint as needed
  return response.data
});
export const deleteRobot = createAsyncThunk('/bots/deleteRobot', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/deleteRobot', configBot); // Adjust endpoint as needed
  return response.data
});
export const sellAll = createAsyncThunk('/bots/sellAll', async (configBot) => {
  const response = await api.post('/sellAll', configBot); // Adjust endpoint as needed
  return response.data
});
export const removeAll = createAsyncThunk('/bots/removeAll', async (configBot) => {
  const response = await api.post('/removeAll', configBot); // Adjust endpoint as needed
  return response.data
});
export const startRobot = createAsyncThunk('/bots/startRobot', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/startRobot', configBot); // Adjust endpoint as needed
  return response.data
});
export const getAllBuys = createAsyncThunk('/bots/getAllBuys', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/getAllBuys', configBot); // Adjust endpoint as needed
  return response.data
});
export const getAllSells = createAsyncThunk('/bots/getAllSells', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/getAllSells', configBot); // Adjust endpoint as needed
  return response.data
});
export const saveTelegramGroup = createAsyncThunk('/bots/saveTelegramGroup', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/addTelegramGroup', configBot); // Adjust endpoint as needed
  return response.data
});
export const changeStopBuy = createAsyncThunk('/bots/changeStopBuy', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/changeStopBuy', configBot); // Adjust endpoint as needed
  return response.data
});
export const getHistoryBuys = createAsyncThunk('/bots/getHistoryBuys', async (configBot) => {
  // console.log(configBot)
  const response = await api.post('/getEarningsByDays', configBot); // Adjust endpoint as needed
  return response.data
});
export const fetchAllUsers = createAsyncThunk('/bots/fetchAllUsers', async (configBot) => {
  const response = await api.post('/getAllUsers', configBot); // Adjust endpoint as needed
  return response.data
});
export const requestSubscriptionSlice = createAsyncThunk('/bots/requestSubscriptionSlice', async (configBot) => {
  const response = await api.post('/requestSubscription', configBot); // Adjust endpoint as needed
  return response.data
});
export const buyBnbSlice = createAsyncThunk('/bots/buyBnbSlice', async (configBot) => {
  const response = await api.post('/buyBnb', configBot); // Adjust endpoint as needed
  return response.data
});
export const addBinanceIdSlice = createAsyncThunk('/bots/addBinanceIdSlice', async (configBot) => {
  const response = await api.post('/addBinanceId', configBot); // Adjust endpoint as needed
  return response.data
});
export const hasDiscount = createAsyncThunk('auth/hasDiscount', async (credentials) => {
  const response = await api.post('/checkIfUserHasDiscount', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});
export const setupStrategy = createAsyncThunk('auth/setupStrategy', async (credentials) => {
  const response = await api.post('/setupStrategy', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});
export const addConfigBinanceSlice = createAsyncThunk('auth/addConfigBinanceSlice', async (credentials) => {
  const response = await api.post('/addApiKey', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});

const authSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBots.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBots.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(fetchBots.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createBot.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(createBot.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteRobot.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteRobot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(deleteRobot.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(sellAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sellAll.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(sellAll.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(startRobot.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(startRobot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(startRobot.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllBuys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllBuys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(getAllBuys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveTelegramGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveTelegramGroup.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(saveTelegramGroup.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllSells.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllSells.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(getAllSells.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editBot.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editBot.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(editBot.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(changeStopBuy.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeStopBuy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(changeStopBuy.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getHistoryBuys.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHistoryBuys.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(getHistoryBuys.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(requestSubscriptionSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(requestSubscriptionSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(requestSubscriptionSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(buyBnbSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(buyBnbSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(buyBnbSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addBinanceIdSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addBinanceIdSlice.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(addBinanceIdSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removeAll.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeAll.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
      })
      .addCase(removeAll.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(hasDiscount.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(hasDiscount.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(hasDiscount.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(setupStrategy.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setupStrategy.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(setupStrategy.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addConfigBinanceSlice.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addConfigBinanceSlice.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(addConfigBinanceSlice.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

export default authSlice.reducer;
