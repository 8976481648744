import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';
import './../css/Login.css';
import { Link, useNavigate } from 'react-router-dom';
const helper = require('./../utils/helper')

const Login = ({ onLogin, user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()
    // Perform login logic here
    let result = await dispatch(login({ email, password }))
    helper.checkForResponse(result, function (canContinue, result) {
      if (canContinue) {
        console.log(result)
        if (result.isAdmin == true) {
          console.log(result.isAdmin)
          localStorage.setItem('nimdasi', 'eurt')
        }
        localStorage.setItem('nigolcrypto', result.user)
        localStorage.setItem('userInfo', JSON.stringify(result.userInfo))
        onLogin(result.user, true)
        setEmail('')
        setPassword('')
        navigate('/dashboard')
      } else {
        onLogin(false)
        alert(result ? result.statusText : 'error')
      }
    })
    // console.log(result)

  };
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1">Welcome Back to Our Automated Trading Bot!</h1>
          </div>

          {/* Form */}
          <div className="max-w-sm mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email</label>
                  <input onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="form-input w-full text-gray-300" placeholder="you@youremail.com" required />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="password">Password</label>
                  <input onChange={(e) => setPassword(e.target.value)} id="password" type="password" className="form-input w-full text-gray-300" placeholder="Password (at least 10 characters)" required />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  <div className="flex justify-between">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-gray-400 ml-2">Keep me signed in</span>
                    </label>
                    <a href="/forgotpassword" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Forgot Password?</a>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <button type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Sign in</button>
                </div>
              </div>
            </form>
            <div className="text-gray-400 text-center mt-6">
              Don’t you have an account? <Link to="/register" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Register</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
