import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import earningsReducer from './slices/earningsSlice';
import adminReducers from './slices/adminSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    earnings: earningsReducer,
    admin: adminReducers
  },
});
