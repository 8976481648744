import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';


const DetailedTrans = ({ sell, remove, clear, buys }) => {

  const [expandedBuys, setExpandedBuys] = useState({});

  const toggleExpandedBuy = (id) => {
    setExpandedBuys({
      ...expandedBuys,
      [id]: !expandedBuys[id],
    });
  };
  const calculatePercentageBuy = (row, buys) => {
    if (row.consecutiveNumber > 1) {
      let findTheRightObject = buys.findIndex(f => f.symbol == row.symbol && f.consecutiveNumber == row.consecutiveNumber - 1)
      return calculatePercentDifference(buys[findTheRightObject] ? buys[findTheRightObject].initialPrice : 0, row.initialPrice).toFixed(2)
    } else {
      return 0
    }
    function calculatePercentDifference(a, b) {
      const difference = Math.abs(a - b);
      const average = (a + b) / 2;
      const percentDifference = (difference / average) * 100;
      return percentDifference;
    }
  }
  const calculateProfit = (bought, currentPrice) => {
    let initialPrice = bought.initialPrice
    const diff = currentPrice - initialPrice
    const percentageDrop = ((diff) / initialPrice) * 100;
    let color = 'greenPercent'
    if (percentageDrop < 0) {
      color = 'redPercent'
    }
    return (<span className={color}>{percentageDrop && percentageDrop.toFixed(3)}</span>)
  }
  const sellTrans = (row) => {
    sell(row)
  }
  const removeTrans = (row) => {
    remove(row)
  }

  if (buys.length > 0) {
    return (
      <Row>
        <div className='flex justify-start mb-2'>
          <Button className='btn btn-special' onClick={() => { clear() }}>Back</Button>
        </div>
        {buys.map(row => (
          <Col key={row.date} sm={12} md={6} lg={6}>
            <Card className='mb-3'>
              <div className="date-corner">
                <div className='d-flex justify-content-center'>
                  {new Date(parseInt(row.date)).toLocaleDateString()}
                </div>
                <div className='d-flex justify-content-center'>
                  {new Date(parseInt(row.date)).toLocaleTimeString()}
                </div>
              </div>
              <Card.Body>
                <Card.Title>{row.symbol}</Card.Title>
                <Card.Text>
                  <div className='flex justify-content-start'>
                    <span className=''>Price:</span>
                    <span className='mx-2 font-bold'>{row.currentPrice}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Call Margin:</span>
                    <span className='mx-2 font-bold'>{row.consecutiveNumber}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Initial Buy:</span>
                    <span className='mx-2 font-bold'>{row.initialPrice}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>% Buy:</span>
                    <span className='mx-2 font-bold'>{calculatePercentageBuy(row, buys)}%</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Margin Trigger Price:</span>
                    <span className='mx-2 font-bold'>{(row.initialPrice - (row.initialPrice * (row.marginBuy ? row.marginBuy : 0.5) / 100)).toFixed(8)}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Profit Trigger Price:</span>
                    <span className='mx-2 font-bold'>{(row.initialPrice + ((row.initialPrice * row.marginCallLimit) / 100)).toFixed(8)}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Profit Trigger Price:</span>
                    <span className='mx-2 font-bold'>{calculateProfit(row, row.currentPrice)}%</span>
                  </div>
                </Card.Text>
                <div className='flex content-start mt-2'>
                  <Dropdown className=''>
                    <Dropdown.Toggle id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => { sellTrans(row) }}>Sell</Dropdown.Item>
                      <Dropdown.Item onClick={() => { removeTrans(row) }}>Remove</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    );
  } else {
    return (null)
  }
};

export default DetailedTrans;
