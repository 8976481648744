import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api'; // Import the Axios instance

const initialState = {
  isAuthenticated: !!localStorage.getItem('nigolcrypto'), // Check if user info exists in localStorage
  user: localStorage.getItem('nigolcrypto') || null,
  isAdmin: localStorage.getItem('nimdasi') && localStorage.getItem('nimdasi') == 'eurt' || null,
  status: 'idle',
  error: null,
};

// Async thunk for login
export const login = createAsyncThunk('auth/login', async (credentials) => {
  const response = await api.post('/loginCrypto', credentials); // Adjust endpoint as needed
  return response.data
});
export const register = createAsyncThunk('auth/register', async (credentials) => {
  const response = await api.post('/registerCrypto', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});
export const saveChangePassword = createAsyncThunk('auth/saveChangePassword', async (credentials) => {
  const response = await api.post('/saveChangePassword', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});
export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (credentials) => {
  const response = await api.post('/forgotPassword', credentials); // Adjust endpoint as needed
  // localStorage.setItem('nigolcrypto', 'test')
  return response.data
});


// Async thunk for logout
export const logout = createAsyncThunk('auth/logout', async () => {
  // const response = await api.post('/logout'); // Adjust endpoint as needed
  localStorage.removeItem('nigolcrypto')
  return {};
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload;
        state.isAdmin = action.payload.result.isAdmin
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(logout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(register.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(register.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(saveChangePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveChangePassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(saveChangePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.isAuthenticated = false;
        state.user = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      
  },
});

export default authSlice.reducer;
