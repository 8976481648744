import React, { useState } from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DialogError = ({ show, handleClose, message, isInfo = true, goToAction = null, goToActionBool, imageUrl = null }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      className="responsive-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isInfo ? "Information" : "Error"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {imageUrl && (
          <Image
            src={imageUrl}
            alt="Dialog Visual"
            fluid
            style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
          />
        )}
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {goToActionBool && (
          <Button variant="primary" onClick={goToAction}>
            Go
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default DialogError