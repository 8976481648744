import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slices/authSlice';
import "../css/header.css"

const Header = ({ user, logout }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const handleLogout = async () => {
    // Add your logout logic here
    localStorage.removeItem('nigolcrypto');
    localStorage.removeItem('nimdasi')
    await logout();
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const scrollHere = () => {
    try {
      if (window.location.pathname == '/home') {
        let el = document.getElementById(`footer-scroll`)
        if (el) {
          el.scrollIntoView(true)
        }

      } else {
        navigate('/home')
        setTimeout(() => {
          let el = document.getElementById(`footer-scroll`)
          if (el) {
            el.scrollIntoView(true)
          }
        }, 1000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <header className="bg-gray-900 text-white">
      <div className="container mx-auto flex justify-between items-center py-2 px-2">
        <div className="text-lg font-bold">
          <div onClick={() => navigate('/home')} className='flex cursor-pointer'>
            <img id='logo' src={require('./public/images/logo1.png')} alt="Logo" className="w-8" />
            {/* <span className='mx-2'>Invest Crypto</span> */}
            <img className='' id="text-logo" src={require('../assets/Invest.png')}></img>
          </div>
        </div>
        <div className="lg:hidden">
          <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <nav className="hidden lg:flex space-x-4">
          {user ? (
            <>
              <span onClick={() => navigate('/home')} className="hover:underline cursor-pointer">Home</span>
              <span onClick={() => navigate('/dashboard')} className="hover:underline cursor-pointer">Dashboard</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/pricing') }} className="hover:underline cursor-pointer">Pricing</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/referral') }} className="hover:underline cursor-pointer">{"Get Free Bots"}</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/howitworks') }} className="hover:underline cursor-pointer">{"How It Works"}</span>
              <span onClick={scrollHere} className="hover:underline cursor-pointer">Contact Us</span>
              {auth.isAdmin ? <span onClick={() => navigate('/selectusers')} className="hover:underline cursor-pointer">Admin</span> : null}
              <span onClick={handleLogout} className="hover:underline cursor-pointer">Logout</span>
            </>
          ) : (
            <>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/home') }} className="hover:underline cursor-pointer">Home</span>
              <span onClick={scrollHere} className="hover:underline cursor-pointer">Contact Us</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/pricing') }} className="hover:underline cursor-pointer">Pricing</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/referral') }} className="hover:underline cursor-pointer">Referral</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/howitworks') }} className="hover:underline cursor-pointer">{"How It Works"}</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/login') }} className="hover:underline cursor-pointer">SignIn</span>
              <span onClick={() => { setIsMobileMenuOpen(false); navigate('/register') }} className="hover:underline cursor-pointer">Register</span>
            </>
          )}
        </nav>
      </div>
      {isMobileMenuOpen && (
        <nav className="lg:hidden bg-gray-900 text-white">
          <div className="flex flex-col items-start p-4 space-y-4">
            {user ? (
              <>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/home') }} className="hover:underline cursor-pointer">Home</span>
                <span onClick={() => { navigate('/dashboard'); setIsMobileMenuOpen(false); }} className="hover:underline cursor-pointer">Dashboard</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/pricing') }} className="hover:underline cursor-pointer">Pricing</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/referral') }} className="hover:underline cursor-pointer">{"Get Free Bots"}</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/howitworks') }} className="hover:underline cursor-pointer">{"How It Works"}</span>
                <span onClick={scrollHere} className="hover:underline cursor-pointer">Contact Us</span>
                {auth.isAdmin ? <span onClick={() => { setIsMobileMenuOpen(false); navigate('/selectusers') }} className="hover:underline cursor-pointer">Admin</span> : null}
                <span onClick={handleLogout} className="hover:underline cursor-pointer">Logout</span>
              </>
            ) : (
              <>
                <span onClick={scrollHere} className="hover:underline cursor-pointer">Contact Us</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/home') }} className="hover:underline cursor-pointer">Home</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/pricing') }} className="hover:underline cursor-pointer">Pricing</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/referral') }} className="hover:underline cursor-pointer">Referral</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/howitworks') }} className="hover:underline cursor-pointer">{"How It Works"}</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/login') }} className="hover:underline cursor-pointer">SignIn</span>
                <span onClick={() => { setIsMobileMenuOpen(false); navigate('/register') }} className="hover:underline cursor-pointer">Register</span>
              </>
            )}
          </div>
        </nav>
      )
      }
    </header >
  );
};

export default Header;
