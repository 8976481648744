'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import VideoThumb from './public/videos/video2.mp4';
import { Container, ListGroup, Button, Row, Col, Modal, Form } from 'react-bootstrap';

const Hero = () => {
  const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: paragraphRef, inView: paragraphInView } = useInView({ triggerOnce: true, threshold: 0.2 });
  const { ref: button1Ref, inView: button1InView } = useInView({ triggerOnce: false, threshold: 0.3 });
  const { ref: button2Ref, inView: button2InView } = useInView({ triggerOnce: false, threshold: 0.4 });
  const { ref: imageRef, inView: imageInView } = useInView({ triggerOnce: false, threshold: 0.5 });

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Illustration behind hero content */}
        <div className="absolute left-0 bottom-0 -ml-20 hidden lg:block pointer-events-none" aria-hidden="true">
          <defs>
            <linearGradient id="illustration-02" x1="-3.766" y1="300.204" x2="284.352" y2="577.921" gradientUnits="userSpaceOnUse">
              <stop stopColor="#5D5DFF" stopOpacity=".01" />
              <stop offset="1" stopColor="#5D5DFF" stopOpacity=".32" />
            </linearGradient>
          </defs>
        </div>

        {/* Hero content */}
        <div className="relative pt-12 pb-10 md:pt-10 md:pb-16">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <motion.h1
              ref={headingRef}
              className="h1 mb-4"
              initial={{ opacity: 0, y: 50 }}
              animate={headingInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              Automated Crypto Trading for Everyone
            </motion.h1>
            <motion.p
              ref={paragraphRef}
              className="text-xl text-gray-400 mb-8"
              initial={{ opacity: 0, y: 50 }}
              animate={paragraphInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Our automated crypto trading platform works seamlessly on all devices, ensuring you can trade effortlessly, anytime, anywhere. Set it up once, and enjoy continuous, profitable results.
            </motion.p>
            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
              <motion.div
                ref={button1Ref}
                initial={{ opacity: 0, y: 50 }}
                animate={button1InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <a className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0" href="/register">Start Now</a>
              </motion.div>
              <motion.div
                ref={button2Ref}
                initial={{ opacity: 0, y: 50 }}
                animate={button2InView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <a className="btn text-white bg-gray-700 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" onClick={() => {
                  document.getElementById('features-id').scrollIntoView()
                }}>Learn more</a>
              </motion.div>
            </div>
          </div>
          <Row>
            <Col sm={12} md={6} lg={6} className="text-center">
              <motion.h1
                className="h1 mb-4">
                One Week
              </motion.h1>
              <motion.h1
                className="h1 mb-4">
                Booster Subscription
              </motion.h1>
              <motion.p
                className="text-xl text-gray-400 mb-8">
                Get to 15 more bots working for 1 week and monitor your profit to see the results!
              </motion.p>
              <motion.p
                className="text-xl text-gray-400 mb-8">
                Build your portfolio while you sleep using automated bots designed by experts by simply registering on our website.
              </motion.p>
              <hr className='mb-5'></hr>
              <motion.h1
                className="h1 mb-4">
                Manage
              </motion.h1>
              <motion.h1
                className="h1 mb-4">
                Dashboard
              </motion.h1>
              <motion.p
                className="text-xl text-gray-400 mb-8">
                Manage your dashboard from your computer or mobile simply by entering your profile.
              </motion.p>
              <motion.p
                className="text-xl text-gray-400 mb-8">
                Send us an email to receive advice on which crypto tokens we recommend setting.
              </motion.p>

            </Col>
            <Col sm={12} md={6} lg={6} className="txt-cent ">
              <motion.video
                ref={imageRef}
                src={VideoThumb}
                width={450}
                height={425}
                initial={{ opacity: 0, y: 50 }}
                animate={imageInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.3 }}
                autoPlay={true}
                loop
                controls
                muted
                playsInline
              />
            </Col>
          </Row>

          {/* <video src={require('./public/videos/video2.mp4')}
            autoPlay={true}
            loop
            muted
            controls
            width={250}
            height={576}></video> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
