import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../redux/slices/authSlice';
import './../css/Login.css';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = ({ onLogin, user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSent, setIsSent] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()
    // Perform login logic here
    let result = await dispatch(forgotPassword({ email }))
    if (result.payload) {
      setIsSent(true)
      // navigate('/dashboard')
    } else {
      onLogin(false)
      alert(result.payload ? result.payload.result.message : 'error')
    }
  };
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Page header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1">Forgot Password</h1>
          </div>

          {/* Form */}
          <div className="max-w-sm mx-auto">
            {isSent ? <div className="text-gray-400 text-center mt-6">
              We have sent an email with your new password. Please check your email inbox.
            </div> :
              <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email</label>
                    <input onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="form-input w-full text-gray-300" placeholder="you@youremail.com" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button type="submit" className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Reset Password</button>
                  </div>
                </div>
              </form>}
            <div className="text-gray-400 text-center mt-6">
              Don’t you have an account? <Link to="/register" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Register</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
