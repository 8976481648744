import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';


const History = ({ clear, history, getMoreHistory }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isHitLimit, setIsHitLimit] = useState(false)
  const [currHistory, setCurrHistory] = useState(history)
  const calculatePercentageBuy = (row, buys) => {
    if (row.consecutiveNumber > 1) {
      let findTheRightObject = buys.findIndex(f => f.symbol == row.symbol && f.consecutiveNumber == row.consecutiveNumber - 1)
      return calculatePercentDifference(buys[findTheRightObject] ? buys[findTheRightObject].initialPrice : 0, row.initialPrice).toFixed(2)
    } else {
      return 0
    }
    function calculatePercentDifference(a, b) {
      const difference = Math.abs(a - b);
      const average = (a + b) / 2;
      const percentDifference = (difference / average) * 100;
      return percentDifference;
    }
  }
  const calculateProfit = (bought, currentPrice) => {
    let initialPrice = bought.initialPrice
    const diff = currentPrice - initialPrice
    const percentageDrop = ((diff) / initialPrice) * 100;
    let color = 'greenPercent'
    if (percentageDrop < 0) {
      color = 'redPercent'
    }
    return (<span className={color}>{percentageDrop && percentageDrop.toFixed(3)}</span>)
  }
  const groupBy = (array, property) => {
    return array.reduce((acc, obj) => {
      const key = obj.responseBuyFromBinance[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  const unifyAndRemoveDuplicates = (arr1, arr2, key) => {
    const unifiedArray = arr1.concat(arr2);
    const uniqueArray = unifiedArray.filter((obj, index, self) =>
      index === self.findIndex((el) => el[key] === obj[key])
    );
    return uniqueArray;
  };
  const loadMore = async () => {
    setIsLoading(true)
    let results = await getMoreHistory(currHistory.length)
    let mutableBuys = [...results];
    let currData = mutableBuys.sort((a, b) => {
      return new Date(b._id) - new Date(a._id);
    });
    if (currData.length == 0) {
      setIsHitLimit(true)
      setIsLoading(false)
    } else {
      if (currHistory.findIndex(f => f._id == results[0]._id) != -1) {
        setIsHitLimit(true)
        setIsLoading(false)
      } else {
        setCurrHistory(unifyAndRemoveDuplicates(currHistory, currData, '_id'))

        setIsLoading(false)
      }
    }

  }
  const render = (row) => {
    let curr = groupBy(row.data, 'symbol')
    let arr = []
    for (const key in curr) {
      if (Object.hasOwnProperty.call(curr, key)) {
        const element = curr[key];
        const totalSalary = element.reduce((acc, obj) => acc + obj.profitTrans, 0);
        arr.push({ symbol: key, profit: totalSalary })
      }
    }
    let mutableBuys = [...arr]
    let sorted = mutableBuys.sort((a, b) => {
      if (b.profit < a.profit) return -1;
      if (a.profit > b.profit) return 1;
      return 0;
    })
    return sorted.map((value, index) => {
      return (<div className='flex justify-content-start'>
        <span className=''>{value.symbol}:</span>
        <span className='mx-2 font-bold'>{value.profit.toFixed(2)}$</span>
      </div>)
    })
    // setInterval(() => {
    //   if (arr.length == curr.length) {
    //     clearImmediate()

    //     })
    //   }
    //   console.log(arr.length)
    //   console.log(curr.length)
    // }, 1000)
  }


  if (currHistory.length > 0) {
    return (
      <Row className='my-4'>
        <div className='flex justify-start mb-2'>
          <Button className='btn btn-special' onClick={() => { clear() }}>Back</Button>
        </div>
        {currHistory.map(row => (
          <Col key={row.date} sm={12} md={6} lg={6}>
            <Card id="special-card" className='mb-3'>
              <Card.Body>
                <Card.Title id="underline" className='flex justify-between'>
                  <span>{row._id}</span>
                  <span><div className='flex justify-content-start'>
                    <span className=''>Profit:</span>
                    <span className='mx-2 font-bold'>{row.profit.toFixed(2)}$</span>
                  </div></span>
                </Card.Title>
                <Card.Text >
                  <div id="special-text">
                    {render(row)}
                  </div>
                  {/* <div className='flex justify-content-start'>
                    <span className=''>Call Margin:</span>
                    <span className='mx-2 font-bold'>{row.consecutiveNumber}</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Initial Buy:</span>
                    <span className='mx-2 font-bold'>{row.initialPrice}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>% Buy:</span>                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Margin Trigger Price:</span>
                    <span className='mx-2 font-bold'>{(row.initialPrice - (row.initialPrice * (row.marginBuy ? row.marginBuy : 0.5) / 100)).toFixed(8)}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Profit Trigger Price:</span>
                    <span className='mx-2 font-bold'>{(row.initialPrice + ((row.initialPrice * row.marginCallLimit) / 100)).toFixed(8)}$</span>
                  </div>
                  <div className='flex justify-content-start'>
                    <span className=''>Profit Trigger Price:</span>
                    <span className='mx-2 font-bold'>{calculateProfit(row, row.currentPrice)}%</span>
                  </div> */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {isLoading ? <div className="loader"></div> : <Col lg={12}>
          {!isHitLimit ? <Button onClick={() => { loadMore() }} variant="primary" className='btn-special-cry'>Load More</Button> : null}
        </Col>}
      </Row>
    );
  } else {
    return (<div className='flex justify-start mb-2'>
      <Button className='btn btn-special' onClick={() => { clear() }}>Back</Button>
    </div>)
  }
};

export default History;
