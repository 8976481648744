'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FeatImage01 from './public/images/more_gains.webp';
import FeatImage02 from './public/images/more_gains2.webp';
import FeatImage03 from './public/images/features-03-image-03.png';

const Zigzag = () => {
  const { ref: image1Ref, inView: image1InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const { ref: content1Ref, inView: content1InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const { ref: image2Ref, inView: image2InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const { ref: content2Ref, inView: content2InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const { ref: image3Ref, inView: image3InView } = useInView({ triggerOnce: false, threshold: 0.5 });
  const { ref: content3Ref, inView: content3InView } = useInView({ triggerOnce: false, threshold: 0.5 });

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <div className="inline-flex text-sm font-semibold py-1 px-3 m-2 text-green-600 bg-green-200 rounded-full mb-4">Reach financial goals with ease</div>
            <h1 className="h2 mb-4">One product, unlimited trading solutions</h1>
            <p className="text-xl text-gray-400">Optimize your trading strategy effortlessly with our state-of-the-art automated trading bot, designed to maximize your profits and minimize risks.</p>
          </div>

          {/* Items */}
          <div className="grid gap-20">

            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <motion.div
                ref={image1Ref}
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1"
                initial={{ opacity: 0, x: -50 }}
                animate={image1InView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage01} width={540} height={405} alt="Features 01" />
              </motion.div>
              {/* Content */}
              <motion.div
                ref={content1Ref}
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                initial={{ opacity: 0, x: 50 }}
                animate={content1InView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-purple-600 mb-2">More gains. Less hassle.</div>
                  <h3 className="h3 mb-3">Keep trades on autopilot</h3>
                  <p className="text-xl text-gray-400 mb-4">Our automated trading bot allows you to trade 24/7, ensuring you never miss an opportunity. Powered by advanced algorithms and real-time data, it makes trading simpler and more efficient.</p>
                  <ul className="text-lg text-gray-400 -mb-2">
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Maximize returns with algorithmic trading</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Reduce emotional decision-making</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Trade across multiple markets effortlessly</span>
                    </li>
                  </ul>
                </div>
              </motion.div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <motion.div
                ref={image2Ref}
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl "
                initial={{ opacity: 0, x: -50 }}
                animate={image2InView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <img className="max-w-full mx-auto md:max-w-none h-auto" src={FeatImage02} width={540} height={405} alt="Features 02" />
              </motion.div>
              {/* Content */}
              <motion.div
                ref={content2Ref}
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                initial={{ opacity: 0, x: 50 }}
                animate={content2InView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5 }}
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div className="font-architects-daughter text-xl text-purple-600 mb-2">Higher Profits. Lower Effort.</div>
                  <h3 className="h3 mb-3">Trade Hands-Free</h3>
                  <p className="text-xl text-gray-400 mb-4">Our automated trading bot operates around the clock, ensuring you never miss a trading opportunity. Leveraging advanced algorithms and real-time data, it streamlines your trading process.</p>
                  <ul className="text-lg text-gray-400 -mb-2">
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Boost returns with algorithmic trading</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Eliminate emotional trading decisions</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Trade across diverse markets effortlessly</span>
                    </li>
                  </ul>
                </div>
              </motion.div>
            </div>

            {/* 3rd item */}
            {/* Add more items as needed */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Zigzag;
