module.exports = {
  checkForResponse: function (result, callback) {
    if (result && result.payload) {
      if (result.payload.error || (result.payload.statusCode != 200 && result.payload.statusCode != 201)) {
        callback(false, result.payload, result.payload.statusText)
      } else {
        callback(true, result.payload.result, result.payload.statusText)
      }
    } else {
      callback(false, { error: "Error" })
    }
  }
}