import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllUsers, deleteUserSlice, addSubscriptionSlice, checkBalancesSlice } from '../redux/slices/adminSlice';
import { Container, Card, Button, Row, Col, Modal, Form, Dropdown } from 'react-bootstrap';
import { logout, saveChangePassword } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import ReactPullToRefresh from 'react-simple-pull-to-refresh'
import "../css/Dashboard.css";
import AdminUsers from './AdminUsers';
import History from './History';
const helper = require('./../utils/helper')

const SelectUsers = ({ user, setUserToCheck, isAdmin }) => {
  // useStickyHeader();
  const [robots, setRobots] = useState([]);
  const [searchTerm, setSearchTerm] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (user && isAdmin) {
      fetchEarnings();
    } else {
      navigate('/home');
    }
  }, [dispatch]);


  const fetchEarnings = async () => {
    let cacheUsers = localStorage.getItem('users')
    if (cacheUsers) {
      cacheUsers = JSON.parse(cacheUsers)
      console.log(`here`)
      if (parseInt(cacheUsers.updateDate) + (5 * 60 * 1000) > parseInt(new Date().getTime())) {
        console.log(`here2`)
        setRobots(cacheUsers.users);
      } else {
        let result = await dispatch(fetchAllUsers({ userId: user }));
        if (result.payload) {
          localStorage.setItem('users', JSON.stringify({ users: result.payload.result.users, updateDate: new Date().getTime() }))
          setRobots(result.payload.result.users);
        } else {
          navigate('/home')
        }
      }
    } else {
      let result = await dispatch(fetchAllUsers({ userId: user }));
      if (result.payload) {
        localStorage.setItem('users', JSON.stringify({ users: result.payload.result.users, updateDate: new Date().getTime() }))
        setRobots(result.payload.result.users);
      } else {
        navigate('/home')
      }
    }
  };

  const setCurrUser = (id) => {
    setUserToCheck(id)
    navigate('/nimda')
  }
  const deleteUser = async (id) => {
    let check = window.confirm('Are you sure?')
    if (check) {
      localStorage.removeItem('users')
      let result = await dispatch(deleteUserSlice({ userId: id }));
      helper.checkForResponse(result, function (canContinue, result, message) {
        if (canContinue) {
          fetchEarnings()
          alert(message)
        }
      })

    }
  }
  const setSubscription = async (id, plan, period) => {
    let check = window.confirm('Are you sure?')
    if (check) {
      localStorage.removeItem('users')
      let result = await dispatch(addSubscriptionSlice({ userId: id, plan, period }));
      helper.checkForResponse(result, function (canContinue, result, message) {
        if (canContinue) {
          fetchEarnings()
          alert(message)
        }
      })
    }
  }

  const calculateTotalBots = () => {
    let bots = filteredRows()
    let sum = 0
    let activeBots = 0
    let needToBuy = 0
    bots.map((value) => {
      sum += value.bots.length
      activeBots += value.bots.filter(f => f.isStarted).length
      needToBuy += value.bots.filter(f => f.needToBuy).length
    })
    return { sum, activeBots, needToBuy }
  }
  const checkBalances = async (id) => {
    let result = await dispatch(checkBalancesSlice({ userId: id }));
    return result.payload.result
  }
  const filteredRows = () => {
    if (searchTerm != '') {
      return robots.filter(f => f.email.toLowerCase().includes(searchTerm.toLowerCase()))
    } else {
      return robots
    }
  }

  const renderMethod = () => {
    console.log(filteredRows())
    console.log(robots)
    return (<AdminUsers
      setCurrUser={setCurrUser}
      buys={filteredRows()}
      deleteUser={deleteUser}
      setSubscription={setSubscription}
      checkBalances={checkBalances}
    >
    </AdminUsers>)
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleRefresh = () => {
    return new Promise((resolve) => {
      // Simulate an async action like fetching data
      setTimeout(() => {
        localStorage.removeItem('users')
        resolve();
      }, 1000); // 2 seconds delay
    });

  }

  if (user && isAdmin) {
    return (
      <Container>
        <ReactPullToRefresh onRefresh={handleRefresh}>
          <h2 id="init-point" className='text-center'>Admin</h2>
          <div className='my-4'>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL USERS:</span>
              <span className='bold-span ml-2'>{filteredRows().length}</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL BOTS:</span>
              <span className='bold-span ml-2'>{calculateTotalBots().sum}</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL ACTIVE BOTS:</span>
              <span className='bold-span ml-2'>{calculateTotalBots().activeBots}</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>TOTAL NEED TO BUY BOTS:</span>
              <span className='bold-span ml-2'>{calculateTotalBots().needToBuy}</span>
            </div>
            <div id='test-line' className='d-flex justify-content-between'>
              <span className=''>ACTIVE SUBSCRIPTIONS:</span>
              <span className='bold-span ml-2'>{filteredRows().filter(f => f.hasSubscription).length}</span>
            </div>
          </div>
          <input
            type="text"
            className="form-control my-2"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {renderMethod()}
        </ReactPullToRefresh>
      </Container>
    );
  } else {
    navigate('/')
  }
};

export default SelectUsers;
