// src/PricingCard.js
import React, { useState } from 'react';
import '../css/pricingCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { requestSubscriptionSlice } from '../redux/slices/earningsSlice';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, } from 'react-bootstrap';


const PricingCard = ({ plan }) => {
  const auth = useSelector((state) => state.auth);
  const [showModalInfo, setShowModalInfo] = useState(false)
  const [selectedSubs, setSelectedSubs] = useState({})

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestSubscription = (subs, name, price) => {
    if (auth.isAuthenticated) {
      let check = ''
      if (plan.discount) {
        check = window.confirm(`Are you sure you want to request this ${name} subscription for $${parseInt(price / 2)}? After clicking 'Yes,' you will see the next steps.`)
      } else {
        check = window.confirm(`Are you sure you want to request this ${name} subscription for $${price}? After clicking 'Yes,' you will see the next steps.`)
      }
      if (check) {
        setShowModalInfo(true)
        setSelectedSubs(subs)
      }
    } else {
      navigate('/register')
    }
  }
  const confirmSubscription = async () => {
    if (auth.isAuthenticated) {
      let result = await dispatch(requestSubscriptionSlice({ plan: selectedSubs.price, userId: auth.user }));
      setShowModalInfo(false)
      navigate('/dashboard')
    }
  }
  const renderModalHowPaySubscription = () => {
    return (
      <Modal id='modal' size="lg" show={showModalInfo} onHide={() => { setShowModalInfo(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Subscribe to Our Crypto Bot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Welcome! To subscribe to our Crypto Bot, please follow these steps:
          </p>
          <ol>
            <li>
              <strong>Create a Binance Account:</strong> The most important
              thing is to ensure you have a Binance account. If you don't have
              one yet, please sign up at{' '}
              <a href="https://www.binance.com/" target="_blank" rel="noopener noreferrer">
                Binance
              </a>
              .
            </li>
            <li>
              <strong>Transfer Subscription Fee:</strong> Transfer the
              subscription fee to our Binance account. You can choose to pay
              either monthly or yearly. Please make sure to include a note with
              your email address of <b>https://investcryptobot.com/</b> during the transfer. BINANCE ID:<b>887224752</b>
            </li>
            <li>
              <strong>Payment Processing:</strong> We will process your payment
              within the next 24 hours.
            </li>
            <li>
              <strong>Account Activation:</strong> Once your payment is
              confirmed, we will notify you via email. Your account will then be
              ready for trading.
            </li>
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowModalInfo(false) }}>
            Close
          </Button>
          <Button variant="primary" onClick={() => { confirmSubscription() }}>
            Confirm Subscription
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <>
      <div class="pricing-table gprice-single">
        <div class="head">
          <h4 class="title">{plan.plan}</h4>
        </div>
        <div class="content">
          <div class="price">
            {/* Show discount price */}
            {plan.discount ? (
              <>
                <h1>
                  <del style={{ fontSize: '25px' }}>${plan.price}</del> ${parseInt(plan.price - plan.price * (plan.discount / 100))}
                </h1>
                <h3>
                  <del style={{ fontSize: '10px' }}>${plan.priceYear}</del> ${parseInt(plan.priceYear - plan.priceYear * (plan.discount / 100))}
                  <span id="price-span">/YEAR</span>
                </h3>
              </>
            ) : (
              <>
                <h1>${plan.price}</h1>
                <h3>${plan.priceYear}<span id="price-span">/YEAR</span></h3>
              </>
            )}
          </div>
          <ul>
            {plan.features.map((value) => {
              if (value.includes) {
                return <li>{value.text}</li>;
              } else {
                return <li><del>{value.text}</del></li>;
              }
            })}
          </ul>
          <div class="sign-up">
            <button onClick={() => { requestSubscription(plan, plan.plan, plan.price) }} class="btn bordered radius">Claim Now!</button>
          </div>
        </div>
      </div>
      {renderModalHowPaySubscription()}
    </>
  );

}

export default PricingCard;
