import React from 'react';
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const DashboardInstructions = () => {
  const navigate = useNavigate()
  return (
    <div className="instruction-how">
      <Container fluid className="p-4">
        <Row className="mb-4">
          <Col>
            <h1 className="text-center">How It Works</h1>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Text>
                  <b>Important Notice for Utilizing Our Bot and System</b> <br />
                  To leverage the full capabilities of our trading bot and system, it is imperative that you possess an active account on Binance. Additionally, users must convert their money to <b>USDT</b> and possess <b>BNB</b>. Please adhere to the following steps to ensure a seamless integration and optimal performance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <h2 className="text-center">How to Create API Keys on Binance?</h2>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Text>
                  <b>Please note that before creating an API Key, you need to:</b> <br />
                  1. enable two-factor authentication (2FA) on your account <br />
                  2. make a deposit of any amount to your Spot Wallet to activate your account <br />
                  3. verify your identity. <br />
                </Card.Text>
              </Card.Body>
              <Card.Body>
                <Card.Title>Creating an API key on Binance is a straightforward process. Here are the steps to guide you through it:</Card.Title>
                <Card.Title>Step 1: Log in to Your Binance Account:</Card.Title>
                <Card.Text>
                  Go to the <a target="_blank" href="https://www.binance.com/en">Binance</a> website and log in with your credentials.
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step5.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 2: Navigate to API Management:</Card.Title>
                <Card.Text>
                  Click on your profile icon in the top right corner.
                  <br />
                  Select “API Management” from the dropdown menu.
                  <br />
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <Image src={require('./public/images/step2.png')} fluid className="mb-3" /> */}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 3: Create a New API Key:</Card.Title>
                <Card.Text>
                  Click on “Create API”.<br />
                  Select your preferred API Key type (System-generated recommended).<br />
                  Enter a label or name for your API key to help you identify it later.<br />
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step6.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 4: Complete Security Verification:</Card.Title>
                <Card.Text>
                  Binance will prompt you to complete Two-Factor Authentication (2FA). This usually involves entering a code sent to your phone or generated by an authenticator app.<br />
                  Confirm the creation of the API key through the email verification link sent to your registered email address.<br />
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <Image src={require('./public/images/step4.png')} fluid className="mb-3" /> */}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 5: Configure API Key Permissions:</Card.Title>
                <Card.Text>
                  Once the API key is created, you can set permissions such as enabling trading, reading data, or withdrawing funds. Be cautious with these settings to ensure your account’s security.<br />
                  <b>We need you to enable only "Enable Reading" and "Enable Spot & Margin Trading"</b>
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <Image src={require('./public/images/step4.png')} fluid className="mb-3" /> */}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 6: Secure Your API Key:</Card.Title>
                <Card.Text>
                  <b>Copy your API key and secret key. The secret key will only be shown once, so make sure to store it securely.</b><br />
                  <b>For added security, restrict access to following specific IP addresses: 13.50.158.160,46.10.148.121,46.10.149.176</b><br />
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step7.png')} fluid className="mb-3" />
          </Col>
        </Row>
        {/* ============ */}
        <Row className="mb-4">
          <Col>
            <h2 className="text-center">Steps for Configuring Your Crypto Bot</h2>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 1: Activate Subscription</Card.Title>
                <Card.Text>
                  Once your subscription is successfully activated, please go to Dashboard.
                </Card.Text>
              </Card.Body>
              <Card.Body>
                <Card.Title>Step 2: Change Password</Card.Title>
                <Card.Text>
                  Dashboard &gt; Actions &gt; Change Password
                  <br />
                  At least $5 of BNBUSDT is required. If you don't have BNB in your Binance account, please go to Actions &gt; "Buy $5 BNB".
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step1.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 3: Add Your Crypto Token</Card.Title>
                <Card.Text>
                  Dashboard &gt; Actions &gt; Add Symbol
                  <br />
                  Example with BTC/USDT:
                  <br />
                  Symbol: BTCUSDT
                  <br />
                  API KEY: Copy the "API KEY" generated from Binance
                  <br />
                  API SECRET: Copy the "Secret KEY" generated from Binance
                  <br />
                  Buy Amount: Specify how much $ you want to use on each buy.
                  <br />
                  Margin: Specify the % to achieve and make profits.
                  <br />
                  Click on "Create" once all details are filled in.
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step2.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Step 4: Start Your Bot</Card.Title>
                <Card.Text>
                  Start your bot by clicking on: Actions &gt; Start Robot
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step3.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Optional: Telegram Notifications</Card.Title>
                <Card.Text>
                  To receive Telegram notifications, create a public group and add the name of the group from: Actions &gt; Add Telegram.
                  <br />
                  Example of group create in Telegram: t.me/telegramgroup
                  <br />
                  Name to be added in InvestCryptoBot website: @telegramgroup
                  <br />
                  Also, you have to add these members to your group: critical_ping_bot and trader_zheko_bot.
                </Card.Text>
              </Card.Body>
            </Card>
            <Image src={require('./public/images/step4.png')} fluid className="mb-3" />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button onClick={() => { navigate('/dashboard') }} variant="primary">Get Started</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardInstructions;
